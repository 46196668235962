

import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { axiosApiGet } from "../../network/api/RequestsApi";

import '../../css/golf-rtl.css'
import '../../css/golfstyle.css'
import '../../css/reset.css'

import Main from './Main';


export default function MainLogic() {

    const navigate = useNavigate();

    const textDocument = useSelector((state) => state.textDocument || {});
    const envDefaults = useSelector((state) => state.envDefaults);
    const [isLoading, setIsLoading] = useState(true);


    const [messages, setMessages] = useState([]);
    const [playerOfTheMonth, setPlayerOfTheMonth] = useState([{}]);


    //Messages & Player Of The Month
    useEffect(() => {
        mainMessages();
        getPlayerOfTheMonth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [envDefaults]);

    // Go Game Order
    async function goGameOrder() {
        navigate('/gameOrder')
    }
    //Messages
    async function mainMessages() {
        setIsLoading(true)
        const res = await axiosApiGet(`/${envDefaults.messagesRoute}`, { language: textDocument.MainLanguage });
        res && setIsLoading(false)
        res?.payload && setMessages(res.payload);

    }
    //Player Of The Month
    const getPlayerOfTheMonth = async () => {
        setIsLoading(true)
        const res = await axiosApiGet(`/${envDefaults.monthPlayerRoute}`, { language: textDocument.MainLanguage });
        res && setIsLoading(false)
        res?.payload && setPlayerOfTheMonth(res?.payload);
    };

    return (
        <Main
            messages={messages}
            playerOfTheMonth={playerOfTheMonth}
            goGameOrder={goGameOrder}
            isLoading={isLoading}
        />

    )
}
