

import Header from "../Header/header";
import Footer from "../Footer";
import { useSelector } from "react-redux";

import "../../css/golf-rtl.css";
import "../../css/golfstyle.css";
import "../../css/reset.css";
import "reactjs-popup/dist/index.css";
import PreviousOrNext from "../PreviousOrNext";
import moment from "moment";
import { Box, CircularProgress } from "@mui/material";



const GuidesDiary = (props) => {

    const { date, setDate, day, apprenticeList, isLoading } = props;

    const textDocument = useSelector((state) => state.textDocument || {});


    return (
        <>
            <Header Login={"Login"} Main={"Main"} />

            <div className="entrance-control">

                <div className="container">
                    <div className="form_title">
                        {" "}
                        <span></span>{" "}
                    </div>
                    <PreviousOrNext date={moment(date).format("DD/MM/YYYY")} day={day} setDate={setDate} guidesDiary={true} />

                    {isLoading &&
                        <Box >
                            <CircularProgress />
                        </Box>}
                    {!isLoading &&
                        <div className="guides-control-table">
                            <div className="row control-head">

                                <div className="col-md-2"> {textDocument.Hour}</div>
                                <div className="col-md-2">{textDocument.Guides} 1</div>
                                <div className="col-md-2">{textDocument.Guides} 2</div>
                                <div className="col-md-2"> {textDocument.Guides} 3</div>
                                <div className="col-md-2">{textDocument.Guides} 4</div>
                                <div className="col-md-2 text-center ">{textDocument.TotalLesson}</div>


                            </div>
                            {apprenticeList?.map((item, i) => (
                                <div key={i} className="row control-line" >
                                    <div className="col-2 col-md-2">{item.hour} </div>
                                    <div className="col-2 col-md-2" >
                                        {item?.apprentice && item?.apprentice?.map((value, i) => (
                                            <div >

                                                <p>  {value?.event_description}</p>
                                                {value?.seq_apprentice === 1 &&
                                                    <div key={i} >
                                                        <p>{value?.apprentice_latin_name ?
                                                            textDocument.language !== "Hebrew" ? value?.apprentice_name : value?.apprentice_latin_name
                                                            :
                                                            value?.apprentice_name
                                                        }</p>
                                                        {value.phone && <a href={`tel:${value.phone}`}>{value?.phone}</a>}

                                                    </div>}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-2 col-md-2">
                                        {item?.apprentice && item?.apprentice?.map((value, i) => (
                                            value.seq_apprentice === 2 &&
                                            <div key={i}>
                                                <p>{value?.apprentice_latin_name ?
                                                    textDocument.language !== "Hebrew" ? value?.apprentice_name : value?.apprentice_latin_name
                                                    :
                                                    value?.apprentice_name
                                                }</p>
                                                {value.phone && <a href={`tel:${value.phone}`}>{value?.phone}</a>}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-2 col-md-2">
                                        {item?.apprentice && item?.apprentice?.map((value, i) => (
                                            value.seq_apprentice === 3 &&
                                            <div key={i}>
                                                <p>{value?.apprentice_latin_name ?
                                                    textDocument.language !== "Hebrew" ? value?.apprentice_name : value?.apprentice_latin_name
                                                    :
                                                    value?.apprentice_name
                                                }</p>
                                                {value.phone && <a href={`tel:${value.phone}`}>{value?.phone}</a>}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-2 col-md-2">
                                        {item?.apprentice && item?.apprentice?.map((value, i) => (
                                            value.seq_apprentice === 4 &&
                                            <div key={i}>
                                                <p>{value?.apprentice_latin_name ?
                                                    textDocument.language !== "Hebrew" ? value?.apprentice_name : value?.apprentice_latin_name
                                                    :
                                                    value?.apprentice_name
                                                }</p>
                                                {value.phone && <a href={`tel:${value.phone}`}>{value?.phone}</a>}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-2 col-md-2 text-center">{item?.total_people} </div>
                                </div>
                            ))}
                        </div>}
                    <Footer />

                </div>
            </div>
        </>
    );
};

export default GuidesDiary;

