
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import "reactjs-popup/dist/index.css";
import "../../css/golf-rtl.css";
import "../../css/golfstyle.css";
import "../../css/reset.css";

import Header from "../Header/header";
import Footer from "../Footer";
import Modal from "../UI-Comps/Modal";
import { setUserName, setWcCodeRedux } from "../../redux/login/LoginAction";

export default function GameOrder(props) {
  const {
    isLoading,
    allWcCode,
    wcCode,
    setWcCode,
    selectedMonth,
    setSelectedMonth,
    availableMonths,
    blockedDays,
    blockDatesInGeneralArray,
    setSelectedOrderDate,
    showPopup,
    setShowPopup,
    goBackToMain
  } = props;

  const textDocument = useSelector((state) => state.textDocument || {});
  const user_type = useSelector((state) => state.detail?.user_type);
  const par18 = useSelector((state) => state.detail?.par18 || false);
  const textPopup = useSelector((state) => state.textPopup);
  const dispatch = useDispatch();

  if (blockedDays?.length && availableMonths?.length) {
    blockDatesInGeneralArray()
  }

  const replyClick = (id) => {
    document.querySelector(`#${id}`).scrollIntoView();
  };

  return (
    <>
      <Header Login={"Login"} OrderList={"OrderList"} Main={"Main"} />
      <Modal
        show={showPopup}
        onCancel={() => {
          setShowPopup(false)
          goBackToMain()
        }

        }
        onSubmit={(e) => {
          e.preventDefault();
          goBackToMain()
        }}
        modalButtonLeft={textDocument.modalButtonLeft}

      >
        <p>{textPopup}</p>
      </Modal>
      <div className="container">
        <div className="form_title">
          <span>{textDocument.HeaderReserveATeeTime}</span>
        </div>
        <div className="butcenter">
          <div className="btn-group-justified but d-flex" role="group">
            {allWcCode?.map((areaType, i) => (
              <div key={i} className="btn-group col-7 " >

                <button
                  type="button"
                  className={`btn ${areaType.id === wcCode ? "btn-success" : "btn-default"
                    } `}

                  id={`ufld:${areaType.id}.DUMMY.MAALE`}
                  onClick={() => {
                    dispatch(setWcCodeRedux(false))
                    setWcCode(areaType.id)
                  }}
                  disabled={user_type === 2 ? false :
                    areaType.id === "par18" && par18 === false ? true : false
                  }
                >
                  {areaType.displayName}
                </button>

              </div>

            ))}
          </div>
        </div>

        {isLoading &&
          <Box >
            <CircularProgress />
          </Box>}


        {(!isLoading && !showPopup) && (
          <>
            <div className="form_subtitle">
              <span>{textDocument.GameOrderSelectDate}</span>
            </div>
            <div className="row month-selection nopadding">
              {availableMonths.length > 1 &&//אם יש חודש אחד רק אז מציג רק את התאריכים של החודש הזה בלי ריבועי החודש למעלה
                availableMonths?.map(({ monthName, year }) => (
                  <div
                    key={monthName + year}
                    // className="col-12 col-sm-6 col-md-4 month-select"

                    className={`col-6 col-sm-3 col-md-3 month-select ${monthName === selectedMonth.monthName &&
                      year === selectedMonth.year &&
                      "month-selected"
                      }`}
                    onClick={() => {
                      replyClick(`${monthName}${year}`);
                      setSelectedMonth({ monthName, year });
                    }}
                  >
                    {textDocument[`${monthName}`]} {year}
                  </div>
                ))}
            </div>
            <div >
              {availableMonths?.map(({ monthName, year, days }, i) => (//החודשים של ריבועי הימים
                <div key={monthName} className="course_calendar">
                  <div id={`${monthName}${year}`} className="month-name">
                    {textDocument[`${monthName}`]} {year}
                  </div>
                  <div className="row DaysSection">
                    {days?.map(({ date, dayName, year, disabled }, index) => (//הימים disabled מחזיר מערך של ימים ואיזה ימים מאופשרים ולא מאופשרים אילה
                      <div
                        key={date}
                        className="col-3 col-md-3 col-lg-3 col-xl-3 text-center day_box button_row"
                        disabled={disabled}
                      >
                        <button
                          onClick={() => {
                            setSelectedOrderDate(date)
                          }
                          }//אם נבחר נצבע
                          className={`thumbnail ${(index === 0 && i === 0) ? 'current' : ''} button_row`}

                          disabled={disabled}
                        >
                          <div
                            className={`day_box_title ${disabled && "disabledtext"}button_row`}
                          >
                            <div
                              className={`date_label ${disabled && "disabledtext"}button_row`}
                            >
                              {date}
                            </div>
                            <div className="day_name">
                              <span>{textDocument[`Day${dayName}`]} </span>

                            </div>
                          </div>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </>)}
        <Footer />
      </div>

    </>
  );
}
