import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import GameTime from "./GameTime";
import { axiosApiGet } from "../../network/api/RequestsApi";
import { DayInWeek } from '../../Logic/dayInWeek'
import { loadAreaDataLogic } from '../GameOrder/logic'
const GameTimeLogic = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const envDefaults = useSelector((state) => state.envDefaults);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [date, setDate] = useState(moment(params.get('selectedDate'), 'DD.MM.YY'));
  const wcCode = params.get('wcCode');
  const selectedTime = params.get('selectedTime');

  // let minute = moment().minute() / 10

  // const [index, setIndex] = useState(((moment().hour() - 6)) * 6 + Math.round(minute));
  let minute;
  if (selectedTime) {
    const [selectedHour, selectedMinute] = selectedTime.split(':');
    minute = parseInt(selectedHour) * 60 + parseInt(selectedMinute) - (6 * 60);
  } else {
    minute = moment().hour() * 60 + moment().minute() - (6 * 60);
  }
  minute = minute / 10;
  
  const [index, setIndex] = useState(Math.round(minute));
  
  const textDocument = useSelector((state) => state.textDocument || {});
  const preorder = useSelector((state) => state.detail?.preorder || false);


  const [isLoading, setIsLoading] = useState(true);
  const [showPopupSubscription, setShowPopupSubscription] = useState(false);
  const [showPopupSubscriptionMessage, setShowPopupSubscriptionMessage] = useState('');

  const [timeHeadersList, setTimeHeadersList] = useState([]);

  const [gameTimeList, setGameTimeList] = useState([]);
  const [isExistUser, setisExistUser] = useState(false);

  const user_type = useSelector((state) => state.detail?.user_type || false);




  useEffect(() => {
    const selectedDate = params.get('selectedDate');
    if (!selectedDate || !wcCode) {
      navigate('/error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  //Summer or winter time
  function checkingWatchType() {

    if (moment(date).isDST()) {//summer =true
      setTimeHeadersList([
        { from: "06:00", to: "12:59", title: textDocument.Morning },
        { from: "13:00", to: "14:59", title: textDocument.Noon },
        { from: "15:00", to: "20:00", title: textDocument.Afternoon },
      ]);
    } else {
      setTimeHeadersList([
        { from: "06:00", to: "11:59", title: textDocument.Morning },
        { from: "12:00", to: "13:59", title: textDocument.Noon },
        { from: "15:00", to: "20:00", title: textDocument.Afternoon },
      ]);
    }
  }

  //Getting array blocked hours
  const getHours = async (hours) => {
    setIsLoading(true);
    const res = await axiosApiGet(`/${envDefaults.blockedHoursRoute}`, { event_date: moment(date, "DD.MM.YY").format("yyyy-MM-DD"), wc_code: wcCode === "par18" ? 1 : 2 });
    res?.payload?.blocked_hours?.length > 0 &&
      res?.payload?.blocked_hours?.forEach((obj, index) => {
        obj?.players?.forEach((player, playerIndex) => {
          if (
            player?.player_name?.seq_player === "1" ||
            player?.player_name?.seq_player === "2" ||
            player?.player_name?.seq_player === "3" ||
            player?.player_name?.seq_player === "4"
          ) {
            player.display = player.player_name?.display;
            player.player_name_display = player?.player_name?.name
            player.player_name = `${textDocument?.player_name} ${player?.player_name?.seq_player}`;


          }
          if (
            player?.player_latin_name?.seq_player === "1" ||
            player?.player_latin_name?.seq_player === "2" ||
            player?.player_latin_name?.seq_player === "3" ||
            player?.player_latin_name?.seq_player === "4"
          ) {
            player.display = player.player_latin_name?.display;
            player.player_latin_name_display = player?.player_latin_name?.name

            player.player_latin_name = `${textDocument?.player_name} ${player?.player_latin_name?.seq_player}`;

          }
        });
      });

    if (res?.payload?.blocked_hours)//res?.payload
    {
      setisExistUser(res?.payload?.is_exist_user_for_this_date ?? false)
      unificationHoursWithBlockedHours(res?.payload?.blocked_hours, hours, res?.payload?.is_exist_user_for_this_date ?? false)//res?.payload
    }
    else if (res.status !== 'ok') {
      setShowPopupSubscriptionMessage(textDocument[res?.error_message] ?? textDocument?.ServerProblem)
      // setGameTimeList([])
      setShowPopupSubscription(true)

      // console.log(res?.error_message);
    }
    setIsLoading(false);
  };

  //If there are consecutive (רצופות) blocked hours, adding from & till
  const hoursFromTill = async (gameTimeList, is_exist_user_for_this_date) => {

    let tillIndex = 0;
    for (let index = tillIndex; index < gameTimeList.length; index = tillIndex++) {
      if (gameTimeList[index].message && gameTimeList[index].message !== "is_exist_user_for_this_date") {
        let message = gameTimeList[index].message
        let from = gameTimeList[index].time;
        let till;
        let index2 = index + 1
        while (index2 < gameTimeList.length && gameTimeList[index2].message && gameTimeList[index2].message === message) {
          // gameTimeList[index2 - 1].time = ""
          gameTimeList[index2 - 1].notShow = gameTimeList[index].permissions ? false : true

          till = !gameTimeList[index].permissions && gameTimeList[index2]?.time;
          index2++;
        }
        if (till && from) {
          tillIndex = index2;
          // gameTimeList[index2 - 1].notShow = true
          // gameTimeList[index2 - 1].time = ""
          gameTimeList[index2 - 1].from = from
          gameTimeList[index2 - 1].till = till

        }
      }

    }
    setGameTimeList(gameTimeList)
  };
  const unificationHoursWithBlockedHours = async (gameTimeList, hours, is_exist_user_for_this_date) => {

    const currentTime = moment();

    const array = hours?.map((val) => {
      if (gameTimeList) {
        const valHour = val?.time?.toString()?.trim();

        const existingHour = gameTimeList?.find(time => time?.time === valHour);
        if (existingHour) {
          return { time: valHour, blocked: existingHour.blocked, message: existingHour.message, players: existingHour.players, permissions: existingHour.permissions };
        } else {
          return { time: valHour, blocked: is_exist_user_for_this_date ? false : true, permissions: valHour.permissions };
          //, blocked: is_exist_user_for_this_date ? true : false
        }
      }
      // Return an empty object if the condition is not met
      return {};
    }).map((element) => {
      const elementTime = moment(element?.time, 'HH:mm');
      const diffMinutes = elementTime.diff(currentTime, 'minutes');
      if (moment(date, 'DD.MM.YY').isSame(moment(), 'day') && (((diffMinutes <= 0 && user_type === 2) || user_type === 1) && !element.message && (!element.players || element?.players?.length <= 0))) {
        return { time: element?.time, blocked: true, permissions: element.permissions };
      } else {
        if (element.hasOwnProperty('blocked')) {
          element.blocked = is_exist_user_for_this_date ? false : true
        }
        // element.players = []
        if (!element.hasOwnProperty('message') && is_exist_user_for_this_date) {
          element.message = 'is_exist_user_for_this_date'
        }
        return element;
      }
    });
    array?.length > 0 && hoursFromTill(array, is_exist_user_for_this_date);
  };


  const clickingLine = (i, returnButton) => {
    if (
      // !gameTimeList[i]?.message && gameTimeList[i]?.blocked && !gameTimeList[i]?.players
      (user_type === 2
        &&
        moment(date, 'DD.MM.YY').isSame(moment(), 'day'))
      ||
      (user_type === 2 && !gameTimeList[i]?.message && !gameTimeList[i]?.blocked
        &&
        !moment(date, 'DD.MM.YY').isSame(moment(), 'day'))
      ||
      (
        user_type === 2 && !gameTimeList[i]?.message && gameTimeList[i]?.blocked && gameTimeList[i]?.players &&
        !moment(date, 'DD.MM.YY').isSame(moment(), 'day')
      )
      ||
      (user_type !== 2 && !gameTimeList[i]?.blocked && !moment(date, 'DD.MM.YY').isSame(moment(), 'day'))
    )
      console.log('');

    if (returnButton) {
      return true
    }
    else {
      user_type === 2 ? setIndex(i) : onOrderGameTime(gameTimeList[i])
    }
  };

  //create hours list
  const hoursList = async () => {

    const hours = []
    for (let hour = 6; hour <= 22; hour++) {
      for (let time = 0; time < 60; time = time + 10) {
        hours.push({ time: moment(`${hour}:${time}`, 'HH:mm').format('HH:mm') })
      }

    }
    setGameTimeList(hours)

    hours && getHours(hours)
  };
  //Next page - GameOrderConfirmation
  const onOrderGameTime = async (gameTimeArray) => {
    const formattedDate = moment(date, ["DD.MM.YY", "YYYY-MM-DD"]).format("DD.MM.YY");
    if (gameTimeArray?.players)
      navigate(`/gameOrder/gameTime/gameOrderConfirmation?selectedDate=${formattedDate}&selectedTime=${gameTimeArray.time}&wcCode=${wcCode}`, { state: { order: gameTimeArray } });
    else
      navigate(`/gameOrder/gameTime/gameOrderConfirmation?selectedDate=${formattedDate}&selectedTime=${gameTimeArray.time}&wcCode=${wcCode}`);

  };

  useEffect(() => {

    if (gameTimeList?.length > 0) {
      getHours(gameTimeList);
      checkingWatchType()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {

    user_type &&
      hoursList()
    checkingWatchType()
    loadAreaDataLogic(dispatch, envDefaults, user_type, preorder, wcCode, user_type === 2 ? true : false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_type]);


  return (
    <GameTime
      isLoading={isLoading}
      setDate={setDate}
      date={date}
      day={DayInWeek(date, textDocument)}
      timeHeadersList={timeHeadersList}
      gameTimeList={gameTimeList}
      wcCode={wcCode}
      clickingLine={clickingLine}
      index={index}
      setIndex={setIndex}
      isExistUser={isExistUser}
      showPopupSubscription={showPopupSubscription}
      showPopupSubscriptionMessage={showPopupSubscriptionMessage}
      setShowPopupSubscription={setShowPopupSubscription}
      hoursList={hoursList}

    />
  );
};

export default GameTimeLogic;
