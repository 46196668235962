import { axiosApi } from "./axiosApi";
import store from '../../redux/Store'

//get env
const getEnv = () => {
  return store?.getState()?.envDefaults
}

//get url
const getUrl = (url) => {

  let envDefaults = getEnv()
  return `${envDefaults?.protocol}://${envDefaults?.basicRoute}/api/v${envDefaults?.version}${url}`;
}

//get
export const axiosApiGet = async (url, body) =>
  axiosApi({ url: getUrl(url), method: "get", body })


//post
export const axiosApiPost = async (url, body) =>
  axiosApi({ url: getUrl(url), method: "post", body })

//delete
export const axiosApiDelete = async (url, body) =>
  axiosApi({ url: getUrl(url), method: "delete", body })

//put
export const axiosApiPut = async (url, body) =>
  axiosApi({ url: getUrl(url), method: "put", body })

//axios internal api get
export const axiosInternalApiGet = async (url) =>
  axiosApi({ url, method: "get" })

