export const ADD_ITEM = "ADD_ITEM";
export const SET_TEXT_DOC = "SET_TEXT_DOC";
export const DELETE_ITEM = "DELETE_ITEM";
export const DIRECTION = "DIRECTION";
export const SET_ENV_DEFAULTS = "SET_ENV_DEFAULTS";
export const SET_MY_ORDER_LIST = "SET_MY_ORDER_LIST";
export const TEXT_POPUP = "TEXT_POPUP";
export const USER_NAME = "USER_NAME";
export const SET_DETAIL = "SET_DETAIL";
export const FROM_DATE = "FROM_DATE";
export const TO_DATE = "TO_DATE";
export const BLOCK_DAYS = "BLOCK_DAYS";
export const SET_WC_CODE = "SET_WC_CODE";


export const CONNECT = "CONNECT";


