
import moment from "moment";


//The day of the week in English / Hebrew
export const DayInWeek = (listDate, textDocument, setOrderList) => {

    if (!Array.isArray(listDate)) {
        let dateTemp;
        if (listDate?.toString().length === 8)
            dateTemp = moment(listDate, "DD.MM.YY").format()
        else
            dateTemp = moment(listDate)

        return textDocument[`Day${moment(dateTemp).format('dddd').substring(0, 3)}`];
    }

    else {
        listDate.forEach(element => {
            let day = moment(element.event_date).format('dddd').substring(0, 3)
            element.day = textDocument[`Day${day}`];
            element.event_date = moment(element.event_date).format("DD.MM.YY")

        });

        setOrderList(listDate);
    }

};
