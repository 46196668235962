import moment from 'moment'
import { format } from "date-fns";
import { axiosApiGet } from "../../network/api/RequestsApi";
import { setArayBlockedDays } from '../../redux/login/LoginAction';

export const loadAreaDataLogic = async (dispatch,envDefaults, user_type, preorder, wcCode) => {
    let untilDay;
    await dateDate((user_type === 1 && preorder === false) ? untilDay = 13 : untilDay = 3)
    if (untilDay === 13) {
        let toDate = moment(await UntilDate(untilDay)).format("yyyy-MM-DD")
        localStorage.setItem('toDate', moment(new Date()).hour() >= 20 ? moment(toDate).add(1, 'day').format("yyyy-MM-DD") : toDate);
  
      } else
        localStorage.setItem('toDate', moment(await UntilDate(untilDay)).format("yyyy-MM-DD"));
  
      localStorage.setItem('fromDate', moment(new Date()).hour() >= 20 ? moment().add(1, 'day').format("yyyy-MM-DD") : moment(new Date()).format("yyyy-MM-DD"));
  
    const res = await axiosApiGet(`/${envDefaults.blockedDatesRoute}`, {
        wc_code:  wcCode==='par18'?1:2, from_date: localStorage.fromDate, to_date: localStorage.toDate,
    }

    );
    dispatch(setArayBlockedDays(res?.payload))
}
const dateDate = async (misDays) => {
    function addDays(date, days) {
        var dat = new Date(date.valueOf())
        dat.setDate(dat.getDate() + days);
        return dat;
    }
    function getDates(startDate, stopDate) {
        var dateArray = [];
        var currentDate = startDate;
        while (currentDate <= stopDate) {
            dateArray.push({ date: currentDate, dayName: currentDate.toString().split(' ')[0], year: (format(currentDate, 'yyyy')) });
            currentDate = addDays(currentDate, 1);
        }
        return dateArray;
    }
}
//The last date in the date range
const UntilDate = async (untilDay) => {
    let lastDay;
    if (untilDay === 3) {

        lastDay = new Date().setMonth(new Date().getMonth() + untilDay)
        lastDay = new Date(lastDay).setDate(new Date(lastDay).getDate() - 1)
    }
    else
        lastDay = addDays(new Date(), untilDay);
    // lastDay = new Date().addDays(untilDay)

    return lastDay;
}

// Function to add days to a date
function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}
