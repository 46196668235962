
import {  Box, CircularProgress } from '@mui/material';
import enterImg from '../../../images/pic-member-entrance.png'
import { useSelector } from "react-redux";

const PopFriendImages = (props) => {

    const { selectedUserImg, selectedEnterImg, loader } = props
    const textDocument = useSelector((state) => state.textDocument || {});

    return (
        <div className="member-entrance-img">
            <div className="text-center">


                <h4 className="modal-title" id="LoadProposalTitle">
                    {textDocument.EntryPicture}
                </h4>

              
                {loader[1]?
                <img src={selectedEnterImg ? `data:image/jpeg;base64,${selectedEnterImg}` :
                    enterImg} alt={selectedEnterImg ? selectedEnterImg :
                        enterImg} style={{ width: "150px" }} />:
                        <Box sx={{height:'40px',marginLeft:2}}> <CircularProgress size="1rem" /></Box>
                        }
            </div>
            <div className="text-center" >
                <h4 className="modal-title" id="LoadProposalTitle">
                    {textDocument.FriendPicture}
                </h4>
                {loader[0]?
                <img src={selectedUserImg ? `data:image/jpeg;base64,${selectedUserImg}` :
                    enterImg} alt={selectedUserImg ? selectedUserImg :
                        enterImg} style={{ width: "150px" }} />:  <Box sx={{height:'40px',marginLeft:2}}> <CircularProgress size="1rem" /></Box>}
            </div>

        </div>
    );
};

export default PopFriendImages;
