import React, { useState } from "react";
import { axiosApiPost } from "../../network/api/RequestsApi";
import { useNavigate } from "react-router-dom";
import Modal from "../UI-Comps/Modal";
import { useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";

const ForgetPassword = ({ isShowForgetPassword, setIsShowForgetPassword }) => {

  const navigate = useNavigate();

  const textDocument = useSelector((state) => state.textDocument || {});
  const envDefaults = useSelector((state) => state.envDefaults);

  const [errorValidEmail, setErrorValidEmail] = useState("");
  const [email, setEmail] = useState();
  const [isLoading, setIsLoading] = useState(false);


  //Validate Email
  let IsValidEmail = () => {
    let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (re.test(email)) {
      setIsLoading(true)
      SendingEmail()
    }
    else {
      setErrorValidEmail(textDocument.RecoverPasswordEmail);

    }

  }

  // Sending Email
  async function SendingEmail() {

    const res = await axiosApiPost(`/${envDefaults.usersRoute}/${envDefaults.passwordRoute}/${envDefaults.forgotRoute}`, { email });
    if(res)       setIsLoading(false)
    if (res?.status === "ok")
      setErrorValidEmail(textDocument.RecoverPasswordLink);
    else
      setErrorValidEmail(textDocument.RecoverPassword);
    setTimeout(() => {
      setIsShowForgetPassword(false)
      setErrorValidEmail("");
      setEmail("");
    }, 6000);
    setTimeout(() => {
      navigate("/Login");
    }, 700);
  }
  const propsModalButton = {
    // modalButtonLeft: textDocument.modalButtonLeft,
    modalButtonRight: textDocument.Closed ,
    modalButtonLeft: errorValidEmail !== textDocument.RecoverPasswordLink && email&& !isLoading  ? textDocument.modalButtonLeft : undefined,

  };

  return (
    <>
      {/* <a> */}
      <span onClick={() => setIsShowForgetPassword(true)}>
        {textDocument.ForgotPassword}
      </span>
      {/* </a> */}

      <Modal
        {...propsModalButton}
        show={isShowForgetPassword}
        onCancel={(e) => {
          e.preventDefault();
          setErrorValidEmail()
          setIsLoading(false)
          setIsShowForgetPassword(false)
        }}
        onSubmit={(e) => {
          e.preventDefault();
          IsValidEmail(e.target.value);
        }}
        header={textDocument.ForgotPassword}
      >
        <div className="forget-password">
          <label htmlFor="email">{textDocument.EnterEmail}</label>

          <input
            id="email"
            type="text"
            name="email"
            onChange={(e) => {
              setEmail(e.target.value);
              setErrorValidEmail("")

            }}

          />
          {isLoading &&
            <Box sx={{ height: '2px', marginLeft: 2 ,mt:-6}}> <CircularProgress size="1rem" /></Box>
          }
          <label>{errorValidEmail}</label>
        </div>
      </Modal>
    </>
  );
};


export default ForgetPassword;

