import { Routes, Route } from "react-router-dom";
import Login from "../components/Login/Login";
import Rules from "../components/Login/Rules";
import Main from "../components/Main/MainLogic";
import Registration from "../components/Login/Registration";
import GameOrderConfirmation from "../components/GameOrderConfirmation/GameOrderConfirmationContainer";
import GameOrder from "../components/GameOrder/GameOrderLogic";
import GameTime from "../components/GameTime/GameTimeLogic";
import MarshalLogic from '../components/marshal/MarshalLogic'
import GuidesDiary from "../components/GuidesDiary/GuidesDiaryLogic"
import OrderList from "../components/OrdersList/OrdersListLogic";
import Error from "../components/Login/Error";
import ResetPassword from "../components/Login/ResetPassword";





function Routing() {
  return (

    <div>
      <Routes>
        <Route path="/login" element={<Login />} />

        {/* <Route path="/home" element={<Home />} /> */}

        <Route
          path="/rules" element={<Rules />} />

        <Route path="/main" element={<Main />} />

        <Route
          path="/registration" element={<Registration />} />

        <Route
          path="/reset_password/:token" element={<ResetPassword />} />

        <Route
          path="/gameOrder" element={<GameOrder />} />

        <Route
          path="/gameOrder/gameTime" element={<GameTime />} />

        <Route
          path="/marshal" element={<MarshalLogic />} />
        <Route
          path="/guidesDiary" element={<GuidesDiary />} />

        <Route
          path="/gameOrder/gameTime/gameOrderConfirmation" element={<GameOrderConfirmation />} />

        <Route
          path="/orderList" element={<OrderList />} />
        <Route
          path="/error" element={<Error />} />
        <Route path="/*" element={<Login />} />
      </Routes>
    </div>

  );
}

export default Routing;
