
import { useSelector } from "react-redux";
import Paper from '@mui/material/Paper';
import Carousel from 'react-material-ui-carousel'
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";

import "reactjs-popup/dist/index.css";
import "../../css/golf-rtl.css";
import "../../css/golfstyle.css";
import "../../css/reset.css";
import Header from "../Header/header";

import Footer from '../Footer'
import playerImg from '../../images/pic-member-entrance.png'
import { Box, CircularProgress } from "@mui/material";

const Main = (props) => {

    const { messages, playerOfTheMonth, goGameOrder, isLoading } = props;
    const textDocument = useSelector((state) => state.textDocument || {});
    const teacher = useSelector((state) => state.detail?.teacher || false);

    const user_type = useSelector((state) => state.detail?.user_type);

    return (
        <div className="main">

            <Header Login={"Login"} OrderList={"OrderList"} GameOrder={"GameOrder"}
                EntranceControl={user_type === 2 ? "EntranceControl" : ""} GuidesDiary={teacher && "GuidesDiary"} Feedback={"Feedback"} />

            <div className="container">
                <div className="jumbotron TopDashboard" >

                    <button id="main-order-button" className="btn btn-success btn-lg" onClick={goGameOrder}
                    >{textDocument.MainBookAGame}
                        {textDocument.language !== "Hebrew" ? <AiOutlineDoubleLeft /> : <AiOutlineDoubleRight />}
                    </button>
                </div>

                {isLoading && <Box sx={{
                    marginTop: '-14%', marginBottom: '3vh',

                    '& .MuiCircularProgress-root': {
                        marginBottom: '0',
                    }
                }}>
                    <CircularProgress />
                </Box>}
                {!isLoading &&
                    <div className={`row d-flex align-items-start ${textDocument.language !== "Hebrew" ? 'RightToLeft' : 'LeftToRight'
                        }`}>
                        <div className="col-12 col-md-4 PaddingBottom20" >
                            <div className="rss_box" >
                                <div className="rss_title" style={{ display: 'table' }} >
                                    <p>{textDocument.MainMessages}</p>
                                </div>
                                {messages?.length > 0 && (
                                    <Carousel>
                                        {messages?.map((item, i) => (
                                            <Item key={i} item={item} />
                                        ))}
                                    </Carousel>
                                )}
                            </div>
                        </div>
                        {/* {playerOfTheMonth?.map((player, i) => ( */}
                        <div className="col-xs-12 col-md-8 MarginBottom20">
                            <div className="player_box d-flex align-items-start" style={{ justifyContent: 'space-between' }}>
                                <div >
                                    <div className="player_title">
                                        <p>{textDocument.MainPlayerOfTheMonth}</p>
                                    </div>
                                    <div className="player_name">
                                        <p>{playerOfTheMonth.name}</p>
                                    </div>
                                    <div className="player_text">
                                        <p>{playerOfTheMonth.text}</p>
                                    </div>
                                </div>
                                <div className="player_picture">
                                    <img
                                        src={playerOfTheMonth.picture ?? playerImg}
                                        alt={textDocument.MainPlayerOfTheMonth}
                                        title={textDocument.MainPlayerOfTheMonth}
                                        className="img-responsive img-circle"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* ))} */}
                    </div>}


                <Footer />
            </div>

        </div>
    )
}
export default Main;

function Item(props) {

    return (
        <Paper className='MainPaperStayle'>
            <p>{props.item.message}</p>
        </Paper>
    )
}
