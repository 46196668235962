import { setDetail } from "../redux/login/LoginAction";
import { axiosInternalApiGet } from "../network/api/RequestsApi";



export const GetDetail = async (dispatch, envDefaults, setReturnGetDetail) => {

    const res = await axiosInternalApiGet(`${envDefaults?.protocol}://${envDefaults?.basicRoute}/api/v${envDefaults?.version}/${envDefaults.usersRoute}/${envDefaults.meRoute}`);

    if (res?.payload) {

        setReturnGetDetail && setReturnGetDetail(true)
        dispatch(setDetail(res.payload))
    } else {
        return false
    }
    return true;
}