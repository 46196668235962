import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header/header';
import { useSelector } from "react-redux";

const Error = () => {
    const textDocument = useSelector((state) => state.textDocument || {});

    return (
        <>
            <Header />
            <div className="container " >


                <div className='bg-image' >
                    <Box
                        display="flex"
                        flexDirection="column"
                        height="60vh"
                        textAlign="center"
                        justifyContent="center"
                    >
                        <Container maxWidth="md">
                            <Typography
                                align="center"
                                variant="h1"
                                sx={{
                                    pt: 2,
                                    color: '#228c3d'
                                }}
                            >
                                404
                            </Typography>
                            <Typography
                                align="center"
                                variant="h4"
                                sx={{
                                    pt: 1,
                                    pb: 3,
                                    color: '#228c3d'
                                }}
                            >
                                {textDocument["The page was not found"]}

                            </Typography>
                            <Button sx={{
                                backgroundColor: "#003B36",
                                color: "white",
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: "#228c3d",
                                }
                            }} variant="contained" component={Link} to="/main" disableElevation>
                                {textDocument["Back to home page"]}
                            </Button>
                        </Container>
                    </Box >

                </div>
                <Footer />
            </div >
        </>)
};

export default Error;
