import { useState } from "react";
import { useSelector } from "react-redux";
import golpherIcon from "../../images/golpher-icon.png";
import golpherClub from "../../images/golpher-club.png";
import clubCar from "../../images/club-car.png";
import Modal from "../UI-Comps/Modal";
import Footer from "../Footer";
import Header from "../Header/header";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import "../../css/golf-rtl.css";
import "../../css/golfstyle.css";
import "../../css/reset.css";
import "reactjs-popup/dist/index.css";
import moment from "moment";

const OrdersList = (props) => {

  const { isLoading, ordersList, onCancelParticipation, editOrder, showNoOrdersPopup, setShowNoOrdersPopup, goMain, setErrorMessage, errorMessage } = props;

  const textDocument = useSelector((state) => state.textDocument || {});
  const detail = useSelector((state) => state.detail || {});
  const [showOrderPopup, setShowOrderPopup] = useState(false);
  const [removeGame, setRemoveGame] = useState();

  //Cancel participation, open popus and save username
  const handleCancelPopups = (event_date, wc_code, start_time) => {

    setErrorMessage(textDocument.noOrdersPopus)
    setShowOrderPopup(true)
    let removeGameTemp = [];
    removeGameTemp.push({ user_code: detail?.user_code, event_date: moment(event_date, "DD.MM.YY").format("yyyy-MM-DD"), wc_code: wc_code, start_time: start_time })
    setRemoveGame(removeGameTemp)
  };


  return (
    <div>
      <>
        <Header Login={"Login"} GameOrder={"GameOrder"} Main={"Main"} />

        <Modal
          show={showNoOrdersPopup}
          onCancel={() => setShowNoOrdersPopup(false)}
          onSubmit={(e) => {
            e.preventDefault();
            goMain()
          }}
          modalButtonLeft={textDocument.modalButtonLeft}
        >
          <p>{errorMessage}</p>
        </Modal>

        <Modal
          show={showOrderPopup}
          onCancel={() => setShowOrderPopup(false)}
          onSubmit={(e) => {
            e.preventDefault();
            const res = onCancelParticipation(removeGame);
            if (res) setShowOrderPopup(false)
          }}
          header={textDocument.headerOrderLisCancel}



          modalButtonLeft={textDocument.modalButtonLeftOrderLisCancel}
          modalButtonRight={textDocument.modalButtonRightOrderLisCancel}

        >
          <p>{textDocument.orderLisCancel}</p>
        </Modal>

        <div className="container">
          <div className="form_title">
            <span>{textDocument.headerMyOrders}</span>
          </div>
          {(isLoading) &&
            <Box >
              <CircularProgress />
            </Box>}
          {!isLoading && ordersList?.map((order, i) => {
            return (
              <div key={i} className="my_orders_panel">
                <div className="colum my_orders_panel_title colum" style={{ display: 'flex' }}>


                  <div className="my_orders_panel_title_date pull-dir">

                    <span> {order.event_date}</span>
                  </div>

                  <div className="my_orders_panel_title_box pull-dir">
                    <div className="my_orders_panel_title_line_1">
                      <span> {order?.day} </span>
                    </div>
                    <div className="my_orders_panel_title_line_2 ">
                      <span>{order?.start_time}</span>
                    </div>
                  </div>

                  {order?.wc_code && <div className="my_orders_panel_title_box pull-dir ">
                    <div className="my_orders_panel_title_line_1">
                      <span>{textDocument.Field}</span>
                    </div>
                    <div className="my_orders_panel_title_line_2">
                      <span>Pete Dye {order?.wc_code === "1" ? "par18" : "par3"} Holes</span>
                    </div>
                  </div>}

                </div>
                {
                  order?.players?.map((player, i) => {
                    return (
                      <div key={i}>
                        <div className="my_orders_golfer_box">
                          <div className="my_orders_golfer_name my_orders_name">
                            <img src={golpherIcon} alt={golpherIcon} />
                            {player?.player_latin_name ?
                              textDocument.language === "אנגלית" ? player.player_name : player.player_latin_name
                              :
                              player?.player_name
                            }
                            {/* <span className="Name">{player?.player_name}</span> */}
                          </div>
                          {player?.stick ?
                            <div className="my_orders_golfer_clubs">
                              <span>
                                <img src={golpherClub} alt={golpherClub} />
                              </span>
                              <span className="TextIdent">
                                {textDocument[player.stick_gender] + " | "}
                                {textDocument[player.stick_kind] + " | "}
                                {textDocument[player.stick_hand]}
                              </span>
                            </div>
                            : ""}
                          {player.car ?
                            <div className="my_orders_club_car">
                              <img src={clubCar} alt={clubCar} />
                            </div>
                            : ""}
                        </div>
                      </div>
                    )

                  })
                }


                <div className="my_orders_footer">

                  {order?.event_date && <div className="pull-dir">
                    <button
                      type="button"
                      className="btn btn-default-remove "
                      onClick={(e) => {
                        handleCancelPopups(order.event_date, order.wc_code, order.start_time)
                      }}
                    >
                      {textDocument.buttonCancelMyOrders}
                    </button>
                  </div>}


                  {order?.event_date && <div className="pull-opp-dir">

                    <button type="button" onClick={() => editOrder(order, order.event_date, order.start_time)} 
                    className="btn btn-success editButton">
                      {textDocument.buttonEditlMyOrders}

                    </button>
                  </div>}
                </div>
              </div>
            );
          })}
          <Footer />
        </div>

      </>

    </div >

  );
};

export default OrdersList;
