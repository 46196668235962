import React from "react";
// import ReactDOM from "react-dom/client";
import ReactDOM from 'react-dom';

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/Store";
import { ThemeProvider } from '@mui/material/styles';
import theme from '../src/css/theme'

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <Provider store={store}>
//     <ThemeProvider theme={theme}>
//       <React.StrictMode>

//         <App />
//       </React.StrictMode>
//     </ThemeProvider>
//   </Provider>
// );
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>

      <React.StrictMode>

        <App />
      </React.StrictMode>
    </ThemeProvider>

  </Provider>,
  document.getElementById('root'),
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// If you want to enable client cache, register instead.
// serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
