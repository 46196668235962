import moment from "moment";
import butDayLeft from "../images/but-day-left.png";
import butWeekLeft from "../images/but-week-left.png";
import butDayRight from "../images/but-day-right.png";
import butWeekRight from "../images/but-week-right.png";
import backToday from "../images/back-today.png";
import { useSelector } from "react-redux";

const PreviousOrNext = (props) => {

    const { date, day, setDate, guidesDiary, marshal } = props

    const textDocument = useSelector((state) => state.textDocument || {});

    const blockedDays = useSelector((state) => state.blockedDays || {});

    const handlePreviousOrNext = (num) => {
        var dateRange = moment(date, "DD/MM/YYYY")
        let startDate = moment(localStorage.fromDate || undefined).format("YYYY-MM-DD");

        var endDate = moment(localStorage.toDate, "YYYY-MM-DD")

        let isRange = dateRange?.isBetween(startDate, endDate);

        if (num === 0) {
            const isNewDateTuday = blockedDays.length > 0 && blockedDays?.some(item => moment(item.date).isSame(new Date(), 'day'));
            if (isNewDateTuday) return;
            else {
                setDate(moment(new Date()))
                return;
            }

        }

        if ((isRange && !marshal)
            ||
            (dateRange.isSame(endDate) && num < 0 && !marshal) ||
            (dateRange.isSame(startDate) && num > 0 && !marshal)
            ||
            (marshal && num < 0) ||

            (marshal && num > 0 && dateRange.isBefore(startDate)) ||
            guidesDiary
        ) {
            if (date?.toString()?.length > 8) {
                const newDate = dateRange.add(num, "days");
                if (!marshal && !guidesDiary) {
                    const isNewDateInArray = blockedDays.length > 0 && blockedDays?.some(item => moment(item.date).isSame(newDate, 'day'));
                    if (isNewDateInArray)
                        handlePreviousOrNext((num < 0) ? num - 1 : num + 1);
                    else {
                        let newDateMoment = moment(newDate)
                        if (newDateMoment?.isSameOrAfter(startDate) && newDateMoment?.isSameOrBefore(endDate))
                            setDate(newDateMoment);
                    }

                }
                else {
                    if (marshal) {
                        if (num > 0) {
                            if (marshal &&( newDate.isBefore(startDate) ||  newDate.isSame(startDate)) )
                                setDate(moment(newDate));

                        } else
                            setDate(moment(newDate));
                    }
                    else
                        setDate(moment(newDate))


                }

            }
            else {
                const newDate = moment(dateRange, "DD.MM.yy").add(num, "days");
                if (!marshal && !guidesDiary) {
                    const isNewDateInArray = blockedDays.length > 0 && blockedDays?.some(item => moment(item.date).isSame(newDate, 'day'));
                    if (isNewDateInArray)
                        handlePreviousOrNext((num < 0) ? num - 1 : num + 1);
                    else {
                        let newDateMoment = moment(newDate).format("DD.MM.yy")
                        if (newDateMoment?.isSameOrAfter(startDate) && newDateMoment?.isSameOrBefore(endDate))
                            setDate(newDateMoment);
                        else
                            handlePreviousOrNext((num < 0) ? num - 1 : num + 1);
                    }
                }
                else {
                    if (marshal) {
                        if (num > 0) {
                            if (marshal &&( newDate.isBefore(startDate) ||  newDate.isSame(startDate)) )
                                setDate(moment(newDate).format("DD.MM.yy"));

                        } else
                            setDate(moment(newDate).format("DD.MM.yy"));
                    }
                    else
                        setDate(moment(newDate).format("DD.MM.yy"));
                }

            }
        };
    }




    return (
        <>
            <div className="row justify-content-center">
                <div className="select_area col-xs-10 col-sm-10 col-md-8 col-lg-6">
                    <div className="form_subtitle ">
                        {" "}
                        {/* <span id="ufld:FIELD_TYPE.DUMMY.MAALE">
                  מגרש: {holes} גומות
                </span>{" "} */}
                    </div>
                    {/* <!-- End form_subtitle --> */}
                    <div className="pick_day_box d-flex justify-content-between">
                        <div className="backward_box">
                            <button
                                type="button"
                                className=""
                                id="ufld:BACKWARD_F.DUMMY.MAALE"
                                aria-label="שבעה ימים אחורה"
                                onClick={() => handlePreviousOrNext(-7)}
                            >
                                <img
                                    src={textDocument.language !== "Hebrew" ? butWeekRight : butWeekLeft}
                                    className="img-responsive-entranceControl"
                                    id="ufld:BACKWARD_F.DUMMY.MAALE"
                                    alt={textDocument.SevenDaysBack}
                                />
                            </button>
                            <button
                                type="button"
                                className=""
                                id="ufld:BACKWARD_S.DUMMY.MAALE"
                                aria-label="יום אחד לאחור"
                                onClick={() => handlePreviousOrNext(-1)}
                            >
                                <img
                                    src={textDocument.language !== "Hebrew" ? butDayRight : butDayLeft}

                                    className="img-responsive-entranceControl"
                                    id="ufld:BACKWARD_S.DUMMY.MAALE"
                                    alt={textDocument.OneDayBack}
                                />
                            </button>
                        </div>
                        {guidesDiary &&
                            <div className="selected_date">
                                <button
                                    type="button"
                                    className=""
                                    id="ufld:FORWARD_F.DUMMY.MAALE"
                                    aria-label="חזור להיום"
                                    onClick={() => handlePreviousOrNext(0)}
                                >
                                    <img
                                        src={backToday}
                                        className="img-responsive-guidesDiary"
                                        id="ufld:FORWARD_F.DUMMY.MAALE"
                                        alt={textDocument.BackToDay}
                                    />
                                </button>

                            </div>}
                        {/* <!-- End back_box --> */}
                        <div className="selected_date">
                            <span id="ufld:PICKDATE.DUMMY.MAALE">
                                {date}
                                {/* {moment(date, "DD/MM/YYYY").format("DD/MM/YYYY")} */}
                            </span>
                            &nbsp;|&nbsp;
                            <span id="ufld:H_E_DAY.DUMMY.MAALE">{day}</span>
                        </div>
                        {/* <!-- End selected_date --> */}
                        <div className="forward_box">
                            <button
                                type="button"
                                className="button_container_forward_box"
                                id="ufld:FORWARD_S.DUMMY.MAALE"
                                aria-label="יום אחד קדימה"
                                onClick={() => handlePreviousOrNext(+1)}
                            >
                                <img
                                    src={textDocument.language !== "Hebrew" ? butDayLeft : butDayRight}

                                    className="img-responsive-entranceControl"
                                    id="ufld:FORWARD_S.DUMMY.MAALE"
                                    alt="יום אחד קדימה"
                                />
                            </button>
                            <button
                                type="button"
                                className="button_container_forward_box"
                                id="ufld:FORWARD_F.DUMMY.MAALE"
                                aria-label="שבעה ימים קדימה"
                                onClick={() => handlePreviousOrNext(+7)}
                            >
                                <img
                                    src={textDocument.language !== "Hebrew" ? butWeekLeft : butWeekRight}

                                    // src={butWeekLeft}
                                    className="img-responsive-entranceControl"
                                    id="ufld:FORWARD_F.DUMMY.MAALE"
                                    alt="שבעה ימים קדימה"
                                />
                            </button>

                        </div>
                        {/* <!-- End forward_box --> */}
                    </div>
                </div>
                {/* <!-- End pick_day_box --> */}
            </div>
        </>
    )
};
export default PreviousOrNext;
