

import { Link } from "react-router-dom";
import { useState } from "react";
import Modal from "../UI-Comps/Modal";
import { axiosApiPost, axiosApiGet } from "../../network/api/RequestsApi";

import { useSelector } from "react-redux";


import "../../css/headerS.css";

import "reactjs-popup/dist/index.css";
import { Box, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#1b7030"
    }, link: {
        color: 'white',
        '&:hover': {
            color: '#b4d8bd'
        }
    }, linkMenue: {
        marginRight:20,
        marginLeft:20,
        marginBottom:3,
        // backgroundColor: "#1b7030",
        color:'#003b36',
        fontWeight:"700",
        '&:hover': {
            color: '#1b7030'
        }
    },
}));
export default function Header(props) {

    const { Login, OrderList, GameOrder, EntranceControl, GuidesDiary, Feedback, Main, Menue } = props
    const classes = useStyles();

    const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
    const [textFeedback, setTextFeedback] = useState(false);
    const [errorValidEmail, setErrorValidEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const textDocument = useSelector((state) => state.textDocument || {});
    const envDefaults = useSelector((state) => state.envDefaults);
    //opem / close popup
    const handleFeedBackClick = () => {
        setErrorValidEmail();
        setShowFeedbackPopup((showFeedbackPopup) => !showFeedbackPopup);
    };
    //Sending messages to Email from Hadass
    const sendingEmailToHadass = async () => {

        const res = await axiosApiPost(`/${envDefaults.feedbackRoute}`, { message: textFeedback });
        setLoading(false)

        if (res?.status === "ok") {
            setErrorValidEmail(textDocument?.SuccessfulSentMail);

        } else setErrorValidEmail(textDocument?.ServerProblem);
    };

    //logout
    const logout = async () => {
        const res = await axiosApiGet(`/${envDefaults.logoutRoute}`);
        localStorage.clear()

        if (res?.status === "ok") {

        }
    };

    const sendFeedbackHandler = (e) => {
        setLoading(true)


        if (textFeedback) {
            sendingEmailToHadass();
            setErrorValidEmail("");
        } else {
            setErrorValidEmail("error");
        }
    };

    return (

        <div 

        >

            {Feedback &&
                <Link onClick={handleFeedBackClick} className={Menue ? '' : classes.link} style={{ textDecoration: Menue ? '' : "none" }} >
                    {Menue && <Typography className={Menue ? classes.linkMenue : classes.link} >{textDocument.HeaderMembersFeedback}</Typography>
                    }
                    {!Menue && <span>{textDocument.HeaderMembersFeedback} &nbsp;</span>}
                </Link>

            }

            <Modal
                show={showFeedbackPopup}
                onCancel={(e) => {
                    handleFeedBackClick();
                    setTextFeedback();
                }}
                onSubmit={(e) => {
                    e.preventDefault();
                    sendFeedbackHandler();
                }}
                header={textDocument.FeedbackMessage}
                modalButtonLeft={!errorValidEmail && ((textFeedback) && textDocument.modalButtonLeft)}
                modalButtonRight={!errorValidEmail ? textDocument.modalButtonRight : textDocument.RulesBack}

            >
                <textarea
                    onChange={(e) => {
                        setTextFeedback(e.target.value);
                    }}
                    id="feedback"
                    name="feedback"
                    rows="5"
                    style={{ width: "100%", margin: "auto" }}
                ></textarea>


                <div style={{ textAlign: textDocument.language !== "Hebrew" ? 'right' : "left" }}>{errorValidEmail}</div>
                {loading && <Box sx={{
                    marginTop: '-20%',
                    marginRight: '45%',
                    marginLeft: '38%',
                    marginBottom: '10%',
                    '& .MuiCircularProgress-root': {
                        marginBottom: '10%',
                        marginRight: '0'
                    }
                }}>
                    <CircularProgress />
                </Box>}
            </Modal>
            {GuidesDiary &&
                <Link className={Menue ? '' : classes.link} style={{ textDecoration: Menue ? '' : "none" }} to="/guidesDiary">
                    {Menue && <Typography className={Menue ? classes.linkMenue : classes.link} >{textDocument.HeaderGuidesDiary}</Typography>
                    }
                    {!Menue && <span>{textDocument.HeaderGuidesDiary} &nbsp;</span>}

                </Link>}


            {
                EntranceControl &&
                <Link className={Menue ? '' : classes.link} style={{ textDecoration: Menue ? '' : "none" }} to="/marshal">
                    {Menue && <Typography className={Menue ? classes.linkMenue : classes.link} >{textDocument.HeaderMarshal}</Typography>
                    }
                    {!Menue && <span>{textDocument.HeaderMarshal} &nbsp;</span>}

                </Link>
            }
            {Main &&
                <Link className={Menue ? '' : classes.link} style={{ textDecoration: Menue ? '' : "none" }} to="/main">
                    {Menue && <Typography className={Menue ? classes.linkMenue : classes.link} >{textDocument.HeaderMain}</Typography>
                    }
                    {!Menue && <span>{textDocument.HeaderMain} &nbsp;</span>}

                </Link>
            }
            {GameOrder &&

                <Link className={Menue ? '' : classes.link} style={{ textDecoration: Menue ? '' : "none" }} to="/gameOrder">
                    {Menue && <Typography className={Menue ? classes.linkMenue : classes.link} >{textDocument.HeaderReserveATeeTime}</Typography>
                    }
                    {!Menue && <span>{textDocument.HeaderReserveATeeTime} &nbsp;</span>}

                </Link>
            }

            {OrderList &&

                <Link className={Menue ? '' : classes.link} style={{ textDecoration: Menue ? '' : "none" }} to="/orderList">
                    {Menue && <Typography className={Menue ? classes.linkMenue : classes.link} >{textDocument.HeaderMyOrders}</Typography>
                    }
                    {!Menue && <span>{textDocument.HeaderMyOrders} &nbsp;</span>}

                </Link>
            }

            {Login &&


                <Link onClick={logout} className={Menue ? '' : classes.link} style={{ textDecoration: Menue ? '' : "none" }} to="/Login">
                    {Menue && <Typography className={Menue ? classes.linkMenue : classes.link} >{textDocument.HeaderLogout}</Typography>
                    }
                    {!Menue && <span>{textDocument.HeaderLogout} &nbsp;</span>}

                </Link>
            }
        </div >




    )

}
