import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Footer from '../Footer'
import Header from '../Header/header'
import { heIL } from '@mui/material/locale';
import { axiosApiPost } from '../../network/api/RequestsApi'
import { useDispatch, useSelector } from "react-redux";
import Modal from '../UI-Comps/Modal';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { setTextPopup } from "../../redux/login/LoginAction";
import { Box } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '0rem',

    },
    title: {
        fontSize: '1.5rem',
        // marginBottom: '2rem',
        // direction: 'rtl',
        color: "#003B36",

    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        maxWidth: '30rem',
        // direction: 'rtl',

    },
    input: {
        marginBottom: '1rem',
        backgroundColor:'white'
        // direction: 'rtl',
        // color: "#",

        // '&:hover': { // add this block
        //     borderColor: '#228c3d',
        // },
    },
    button: {

        // marginTop: '1rem',
        // direction: 'rtl',
    },

}));


const Registration = () => {
    const classes = useStyles();
    const [showPopup, setShowPopup] = useState(false)
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        gender: '',
        birth_date: '',
        address: '',
        city: '',
        street: '',
        house_number: '',
        postal_code: '',
        phone: '',
        additional_phone: '',
        hendicap: '',
        home_court: '',
    });
    const envDefaults = useSelector((state) => state.envDefaults);
    const textDocument = useSelector((state) => state.textDocument || {});
    const textPopup = useSelector((state) => state.textPopup);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const fields = [
        {
            label: textDocument.RegisterUsername,
            required: true,
            state: 'first_name',
        },
        {
            label: textDocument.RegisterFamilyName,
            required: true,
            state: 'last_name',
        },
        {
            label: textDocument.RegisterEmail,
            type: 'email',
            required: true,
            state: 'email',
        },
        {
            label: textDocument.RegisterGender,
            state: 'gender',
        },
        {
            label: textDocument.RegisterBirthdate,
            type: 'date',
            state: 'birth_date',
        },


    ];
    const fields2 = [

        {
            label: textDocument.RegisterAddress,
            required: false,
            state: 'address',
        },
        {
            label: textDocument.RegisterCity,
            required: false,
            state: 'city',
        },
        {
            label: textDocument.RegisterStreet,
            required: false,
            state: 'street',
        },
        {
            label: textDocument.RegisterHouseNo,
            type: 'number',
            state: 'house_number',
        },
        {
            label: textDocument.RegisterPostalCode,
            state: 'postal_code',
        },


    ];
    const fields3 = [

        {
            label: textDocument.RegisterPhoneNo,
            required: true,

            state: 'phone',
        },
        {
            label: textDocument.RegisterAdditionalPhoneNo,
            state: 'additional_phone',
        },
        {
            label: textDocument.RegisterHandicap,
            required: true,
            state: 'hendicap',
        },
        {
            label: textDocument.RegisterHomeClub,
            state: 'home_court',
            required: true,

        },



    ];
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const mandatoryFields = fields.filter((field) => field.required);
        const mandatoryFields2 = fields2.filter((field) => field.required);
        const mandatoryFields3 = fields3.filter((field) => field.required);

        // Check if any mandatory fields are empty
        const emptyFields = mandatoryFields.filter((field) => !formData[field.state]);
        const emptyFields2 = mandatoryFields2.filter((field) => !formData[field.state]);
        const emptyFields3 = mandatoryFields3.filter((field) => !formData[field.state]);

        if (emptyFields.length > 0 || emptyFields2.length > 0 || emptyFields3.length > 0) {
            dispatch(setTextPopup(textDocument.FieldsRequired))

            setShowPopup(true)
            return;
        } else {

            if (formData?.birth_date) {
                let formDataTemp = formData
                formDataTemp.birth_date = moment(new Date(formDataTemp.birth_date), "DD/MM/YYYY").format("MM/DD/YYYY")
                setFormData(formDataTemp)
            }
            const res = await axiosApiPost(`/${envDefaults.registrationRoute}`, formData);
            if (res?.status === "ok") {
                dispatch(setTextPopup(textDocument.RegisterSendingEmailMessage))
            } else {
                dispatch(setTextPopup(textDocument.ServerProblem))

            }
            setShowPopup(true)
        }

    };
    const goBackToLogin = () => {

        navigate(`/Login`);
    };
    return (
        <>
            <Modal
                show={showPopup}
                onCancel={() => setShowPopup(false)}
                onSubmit={(e) => {
                    e.preventDefault();
                    goBackToLogin()
                }}
                modalButtonLeft={textDocument.modalButtonLeft}
            >
                <p>{textPopup}</p>
            </Modal>
            <Header />


            <div className="container " >
                <div className='bg-image' >
                    <div className={classes.container} >

                        <div className={classes.title}>
                            {textDocument.SignUpForm}
                        </div>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            {fields.map((field) => (
                                field.state === "gender" ?
                                    <FormControl key={field.state}
                                        style={{
                                            color: "#003B36", display: 'flex',
                                            flexDirection: 'row'
                                        }}
                                    >
                                        <div  >
                                            {textDocument.RegisterGender}
                                        </div>
                                        <RadioGroup

                                            style={{
                                                marginTop: '-1.5%'
                                            }}
                                            placeholder="dd"
                                            row
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name={field.state}
                                            value={formData[field.state]}
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel value="woman" control={<Radio />} label={textDocument.woman} />
                                            <FormControlLabel value="man" control={<Radio />} label={textDocument.man} />

                                        </RadioGroup>
                                    </FormControl>
                                    :
                                    field.state === "birth_date" ?
                                        <FormControl key={field.state}>
                                            <div style={{ color: "#003B36" }} >
                                                {field.label}
                                            </div>
                                            <TextField
                                                key={field.state}
                                                placeholder={field.label}
                                                type={field.type}
                                                required={field.required}
                                                className={classes.input}
                                                value={formData[field.state]}
                                                name={field.state}
                                                onChange={handleChange}
                                                local={heIL}
                                                color={"success"}
                                                size='small'
                                            />
                                        </FormControl>
                                        :

                                        <TextField
                                            key={field.state}
                                            // label={field.label}
                                            placeholder={field.label}
                                            type={field.type}
                                            required={field.required}
                                            className={classes.input}
                                            value={formData[field.state]}
                                            name={field.state}
                                            onChange={handleChange}
                                            local={heIL}
                                            color={field.required ? "error" : "success"}
                                            size='small'
                                        />

                            ))}
                            <div style={{ color: "#003B36" }} >
                                {textDocument.RegisterAddress}
                            </div>

                            {fields2.map((field) => (

                                <TextField
                                    key={field.state}
                                    // label={field.label}
                                    placeholder={field.label}
                                    type={field.type}
                                    required={field.required}
                                    className={classes.input}
                                    value={formData[field.state]}
                                    name={field.state}
                                    onChange={handleChange}
                                    local={heIL}
                                    color={field.required ? "error" : "success"}
                                    size='small'
                                />

                            ))}
                            {fields3.map((field) => (

                                <TextField
                                    key={field.state}
                                    // label={field.label}
                                    placeholder={field.label}
                                    type={field.type}
                                    required={field.required}
                                    className={classes.input}
                                    value={formData[field.state]}
                                    name={field.state}
                                    onChange={handleChange}
                                    local={heIL}
                                    color={field.required ? "error" : "success"}
                                    size='small'
                                />

                            ))}
                            <Box display="-webkit-flex" justifyContent="space-around" >
                                <Link to="/Login" >
                                    <Button
                                        variant="contained"
                                        size="large"
                                        sx={{
                                            backgroundColor: "#003B36",
                                            color: "white",
                                            "&:hover": {
                                                backgroundColor: "#228c3d",
                                            }
                                        }}
                                    >
                                        {textDocument.RulesBack}
                                    </Button>
                                </Link>

                                <Link to="/Login" >
                                    <Button
                                        variant="contained"
                                        size="large"
                                        onClick={handleSubmit} sx={{
                                            backgroundColor: "#003B36",
                                            color: "white",
                                            "&:hover": {
                                                backgroundColor: "#228c3d",
                                            }
                                        }}
                                    >
                                        {textDocument.FeedbackButton}
                                    </Button>
                                </Link>

                            </Box>

                        </form>
                    </div>
                </div>
                <Footer />
            </div >

        </>
    );
};

export default Registration;