import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from 'moment'
import { axiosApiGet } from "../../network/api/RequestsApi";
import GameOrder from "./GameOrder";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { setArayBlockedDays, setTextPopup, setUserName, setWcCodeRedux } from "../../redux/login/LoginAction";

const GameOrderLogic = () => {


  const navigate = useNavigate();

  const envDefaults = useSelector((state) => state.envDefaults);
  const user_type = useSelector((state) => state.detail?.user_type);
  const par18 = useSelector((state) => state.detail?.par18 || user_type === 2 ? true : false);
  const preorder = useSelector((state) => state.detail?.preorder || false);

  // const par3 = false
  const allWcCode = [
    { id: "par18", displayName: "Pete Dye 18 Holes" },
    { id: "par3", displayName: "PAR3" },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [wcCode, setWcCode] = useState();
  const [selectedMonth, setSelectedMonth] = useState({});
  const [availableMonths, setAvailableMonths] = useState([]);
  const [selectedOrderDate, setSelectedOrderDate] = useState();
  const [blockedDays, setBlockedDays] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();
  const textDocument = useSelector((state) => state.textDocument || {});


  // //Months available
  const loadAreaData = async () => {

    setIsLoading(true);
    let untilDay;
    await SortNameMounth(await dateDate((user_type === 1 && preorder === false) ? untilDay = 13 : untilDay = 3))
    // localStorage.setItem('toDate', moment(await UntilDate(untilDay)).format("yyyy-MM-DD"));
    if (untilDay === 13) {
      let toDate = moment(await UntilDate(untilDay)).format("yyyy-MM-DD")
      localStorage.setItem('toDate', moment(new Date()).hour() >= 20 ? moment(toDate).add(1, 'day').format("yyyy-MM-DD") : toDate);

    } else
      localStorage.setItem('toDate', moment(await UntilDate(untilDay)).format("yyyy-MM-DD"));

    localStorage.setItem('fromDate', moment(new Date()).hour() >= 20 ? moment().add(1, 'day').format("yyyy-MM-DD") : moment(new Date()).format("yyyy-MM-DD"));

    const res = await axiosApiGet(`/${envDefaults.blockedDatesRoute}`, {
      wc_code: wcCode === "par18" || wcCode?.id === "par18" ? 1 : 2, from_date: localStorage.fromDate, to_date: localStorage.toDate,
    }
    );

    if (res) {
      if (res?.payload) {
        dispatch(setArayBlockedDays(res?.payload)); // for Previous or next
        setBlockedDays(res?.payload) //מערך של תאריכים חסומים
        setIsLoading(false);

      }
      else {
        dispatch(setTextPopup(textDocument[res?.error_message] ?? textDocument?.ServerProblem))
        setShowPopup(true)
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);

    }
  };


  const dateDate = async (misDays) => {
    function addDays(date, days) {
      var dat = new Date(date.valueOf())
      dat.setDate(dat.getDate() + days);
      return dat;
    }
    function getDates(startDate, stopDate) {
      var dateArray = [];
      var currentDate = startDate;
      while (currentDate <= stopDate) {
        dateArray.push({ date: currentDate, dayName: currentDate.toString().split(' ')[0], year: (format(currentDate, 'yyyy')) });
        currentDate = addDays(currentDate, 1);
      }
      return dateArray;
    }

    //Checking if it's after eight in the evening
    var todayOrTomorrow = new Date();
    let todayOrTomorrowMisDays = false;
    if (moment(new Date()).hour() >= 20) {
      todayOrTomorrow.setDate(todayOrTomorrow.getDate() + 1);
      todayOrTomorrowMisDays = misDays !== 3 && misDays + 1
    }
    var dateArray = getDates(todayOrTomorrow, await UntilDate(todayOrTomorrowMisDays !== false ? todayOrTomorrowMisDays : misDays));
    return dateArray;
  }
  //Sorting the array by month
  const SortNameMounth = async (date) => {

    date.reduce((acc, val) => {
      const valMonth = moment(val.date).format('MMM');
      const valYear = moment(val.date).format('YYYY');
      const planGroup = acc.find(plan => plan.monthName === valMonth && plan.year === valYear);
      if (!planGroup) {
        acc.push({ monthName: valMonth, year: valYear, days: [val] });
      } else {
        planGroup.days = [...planGroup.days, val];
      }
      val.date = format(val.date, 'dd.MM.yy');
      setAvailableMonths(acc);


      return acc;
    }, []);
  };

  //The last date in the date range
  const UntilDate = async (untilDay) => {
    let lastDay;
    if (untilDay === 3) {
      if (moment(new Date()).hour() >= 20) lastDay = addDays(new Date(), 1);

      lastDay = new Date().setMonth(new Date().getMonth() + untilDay)

      if (moment(new Date()).hour() < 20) lastDay = new Date(lastDay).setDate(new Date(lastDay).getDate() - 1)

    }
    else
      lastDay = addDays(new Date(), untilDay);
    // lastDay = new Date().addDays(untilDay)

    return lastDay;
  }

  // Function to add days to a date
  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }


  //Area type 18 or 3
  const loadAreaType = async () => {
    if (par18 === true)
      setWcCode(allWcCode[0].id);
    else {
      dispatch(setWcCodeRedux(false))
      setWcCode(allWcCode[1].id);
    }
  };

  // //By clicking on a certain date

  const onOrderDate = useCallback(async () => {
    navigate(`/gameOrder/gameTime?selectedDate=${selectedOrderDate}&wcCode=${wcCode}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrderDate, wcCode]);

  useEffect(() => {
    selectedOrderDate && onOrderDate();
  }, [selectedOrderDate, onOrderDate]);


  const goBackToMain = () => {

    navigate(`/main`);
  };
  //Blocking the dates within the general array
  const blockDatesInGeneralArray = async () => {
    availableMonths.forEach(element => {
      element.days.forEach(element_days => {
        let some = blockedDays.length > 0 && blockedDays?.some(bdate => moment(element_days.date, "DD.MM.YY").diff(moment(bdate.date)) === 0)
        if (some)
          element_days.disabled = true;
      });

    });
  };

  useEffect(() => {
    loadAreaType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(async () => {

    wcCode && loadAreaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wcCode]);

  // The test for the first time will not just be worthless


  return (
    < GameOrder
      isLoading={isLoading}
      allWcCode={allWcCode}
      wcCode={wcCode}
      setWcCode={setWcCode}
      selectedMonth={selectedMonth}
      setSelectedMonth={setSelectedMonth}
      availableMonths={availableMonths}
      blockedDays={blockedDays}
      blockDatesInGeneralArray={blockDatesInGeneralArray}
      // selectedOrderDate={selectedOrderDate}
      setSelectedOrderDate={setSelectedOrderDate}
      showPopup={showPopup}
      setShowPopup={setShowPopup}
      goBackToMain={goBackToMain}
    />
  );
};

export default GameOrderLogic;
