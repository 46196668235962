import Header from "../Header/header";
import Footer from "../Footer";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import club_car from "../../images/club_car.png";
// import golpher_lub from "../../images/golpher-club.png";
import { useSelector } from "react-redux";

import "../../css/golf-rtl.css";
import "../../css/golfstyle.css";
import "../../css/reset.css";
import "reactjs-popup/dist/index.css";
import PreviousOrNext from "../PreviousOrNext";
import moment from "moment";
import GameTimeMarshal from './GameTimeMarshal'
import Modal from "../UI-Comps/Modal";

const GameTime = (props) => {

  const { isLoading, setDate, date, day, timeHeadersList, gameTimeList, wcCode, clickingLine, index, setIndex, isExistUser, showPopupSubscription, showPopupSubscriptionMessage, setShowPopupSubscription,hoursList } = props;
  const textDocument = useSelector((state) => state.textDocument || {});
  const user_type = useSelector((state) => state.detail?.user_type);

  const replyClick = (hour) => {

    const hourParts = hour.split(':');
    const hourValue = parseInt(hourParts[0]);
    const minuteValue = parseInt(hourParts[1]);
    const idList = document.querySelectorAll('[id^="till:"], [id^="from:"], [id^="time:"]');
    for (let i = 0; i < idList.length; i++) {

      const id = idList[i].getAttribute('id');

      if (id.startsWith('till:') && id.includes('from:')) {
        const idParts = id.split('+');
        const tillParts = idParts[0].split(':');
        const fromParts = idParts[1].split(':');
        const tillHour = parseInt(tillParts[1]);
        const fromHour = parseInt(fromParts[1]);

        if (hourValue >= fromHour && hourValue <= tillHour) {
          if (hourValue === fromHour && minuteValue < fromParts[2]) {
            continue;
          }
          if (hourValue === tillHour && minuteValue > tillParts[2]) {
            continue;
          }
          document.getElementById(id)?.scrollIntoView();
          break;
        }
      } else
        if (id.startsWith('time:')) {
          const timeParts = id.split(':');
          const timeHour = parseInt(timeParts[1]);
          if (hourValue === timeHour && minuteValue >= 0 && minuteValue <= 59) {
            document.getElementById(id)?.scrollIntoView();
            break;
          }
        }
    }
  };
  // const goBackToGameOrder = () => {

  //   navigate(`/gameOrder`);
  // };
  return (
    <>
      <Header Login={"Login"} Main={"Main"} OrderList={"OrderList"} EntranceControl={user_type === 2 && "EntranceControl"} />
      <div>
        <div className="container">
          <div className="form_title">
            {" "}
            <span>{textDocument.HeaderReserveATeeTime}</span>{" "}
          </div>
          <Modal
            show={showPopupSubscription}
            onCancel={() => {
              setShowPopupSubscription(false)
              // goBackToGameOrder()
            }

            }
            onSubmit={(e) => {
              e.preventDefault();
              // goBackToGameOrder()
            }}
            modalButtonLeft={textDocument.modalButtonLeft}

          >
            <p>{showPopupSubscriptionMessage}</p>
          </Modal>
          {(isLoading || !user_type) &&
            <Box >
              <CircularProgress />
            </Box>}

          {(!isLoading && user_type) &&
            <div className="all">
              {user_type === 2 &&
                <GameTimeMarshal
                hoursList={hoursList}
                  gameTimeList={gameTimeList}
                  wcCode={wcCode}
                  index={index}
                  setIndex={setIndex}
                  date={date}
                  day={day} />}
              <div className="all row justify-content-center ">
                <div className={` ${user_type === 2 ? "headerPartMarshal" : "headerPart"}`}>

                  <span id="ufld:FIELD_TYPE.DUMMY.MAALE">{`Pete Dye ${wcCode} Holes`} </span>{" "}

                </div>

                <PreviousOrNext date={moment(date, "DD.MM.YY").format("DD/MM/YYYY")} day={day} setDate={setDate} />

                <div className={`select_area col-12 col-md-9 col-lg-7 col-xl-6 ${user_type === 2 ? "selectMarshal" : "select"}`} >


                  <div>
                    <div className="clearboth"> </div>
                    <div
                      className={`${user_type === 2 ? 'form_subtitlell_Marshal' : 'form_subtitlell'
                        }`}
                    >
                      {" "}
                      <span>{textDocument.GameTimeSelectTimeRange}</span>{" "}
                    </div>

                    <div
                      // id="choose-hours"
                      className="btn-group-justified but"
                      role="group"
                    >
                      {timeHeadersList?.map((obj, i) => (
                        <button
                          type="button"
                          className="btn btn-default "
                          id="gotomorning"
                          key={`timeHeader-${i}`}
                          onClick={() => {
                            replyClick(obj.from);
                          }}
                        >
                          <div key={i} className="Text14Green">{obj.title}</div>
                          <div className="hour">
                            <b>{obj.from}</b>
                          </div>
                        </button>
                      ))}

                    </div>
                    <div className="select_area hour_spread">
                      <div className="form_subtitle">
                        {" "}
                        <span id="ufld:SELECT_ORDER.DUMMY.MAALE">
                          {textDocument.GameTimeReserveATeeTime}
                        </span>{" "}
                      </div>
                      <div className="hour_list_panel">

                        {gameTimeList?.map((gameTimeItem, i) => {
                          if ((!gameTimeItem.message || gameTimeItem.message === undefined)
                            || gameTimeItem?.permissions)
                            return (
                              <div
                                id={`time:${gameTimeItem.time}`}
                                key={`time:${gameTimeItem.time}`}
                                className="hour_detail"
                              >
                                <div key={i} className="row" onClick={() => {
                                  // gameTimeItem?.players?.length < 4 ||

                                  if ((gameTimeItem?.players === undefined || gameTimeItem?.players.length <= 4) && !isExistUser && !gameTimeItem?.permissions) {
                                    clickingLine(i)
                                  }

                                }
                                }>
                                  <div className="hour_value col-3  col-md-2 col-lg-2">
                                    {" "}
                                    <span id="TEE_TIME" name="TEE_TIME">
                                      {gameTimeItem.time}
                                    </span>
                                  </div>

                                  <div className="hour_detail_box col-6 col-md-8 col-lg-8">
                                    <div
                                      className="row"
                                      // style={{ flexWrap: "wrap", height: "50px" }}
                                    >
                                      {/* רכב גולף */}
                                      {gameTimeItem.players?.map((person, pI) => (
                                        <div
                                          className="col-12 col-lg-6 p-0 m-0 d-flex"
                                          style={{ flexWrap: "wrap" }}
                                          key={pI}
                                        >
                                          <div 
                                            id={`GOLFER_${pI + 1}`}
                                            name={`GOLFER_${pI + 1}`}
                                          >
                                            {!person?.display ?
                                              person.player_latin_name ?
                                                textDocument.language !== "Hebrew" ? person.player_name : person.player_latin_name
                                                :
                                                person.player_name_display : person.player_latin_name_display ?
                                                textDocument.language !== "Hebrew" ? person.player_name_display : person.player_latin_name_display
                                                :
                                                person.player_name_display
                                            }
                                          </div>
                                          <div className="GolfCar" >
                                            {person.car && (
                                              <img src={club_car} alt="רכב גולף" />
                                            )}
                                          </div>
                                          
                                          {/* <div className="GolfCar" >
                                            {person.stick && (
                                              <img src={golpher_lub} alt="רכב גולף" />
                                            )}
                                          
                                          </div> */}
                                        </div>
                                      ))}
                                    </div>
                                  </div>

                                  {/* {clickingLine(i, true)( */}
                                  {!isExistUser &&
                                  <div className="order_box col-3 col-2 col-md-2 col-lg-2"
                                  >
                                    {(user_type === 2 ||(gameTimeItem?.players?.length < 4 || 
                                      gameTimeItem?.players?.length === undefined) && gameTimeItem.blocked && !gameTimeItem?.permissions) ?
                                      <button
                                        type="button"
                                        className="btn btn-success"
                                        id="order"
                                        name="order"

                                      >
                                        {user_type === 2 ? textDocument.Select : textDocument.Reserve}
                                      </button>
                                      : gameTimeItem?.players?.length === 4 && textDocument?.fullGame}
                                  </div>}
                                  {/* ) */}
                                  {/* } */}


                                </div>
                              </div>
                            );

                          if (gameTimeItem.message && gameTimeItem.from && gameTimeItem.till)
                            return (

                              <div
                                id={`till:${gameTimeItem.till} + from:${gameTimeItem.from}`}

                                key={gameTimeItem.till}
                                className="hour_detail"
                              >
                                <div className="row">
                                  <div className="hour_value col-3  col-md-2 col-lg-2">
                                    <span>{gameTimeItem.from}</span>
                                  </div>
                                  <div className="hour_detail_box col-6 col-md-8 col-lg-8">
                                    {/* <p>{gameTimeItem.message}</p> */}
                                  </div>

                                </div>
                              </div>
                            );

                          if (gameTimeItem.message && !gameTimeItem.from && !gameTimeItem.till && gameTimeItem.time && gameTimeItem?.notShow !== true)
                            return (
                              <div
                                id={`time:${gameTimeItem.time}`}
                                key={gameTimeItem.till}
                                className="hour_detail"
                              >
                                <div className="row">
                                  <div className="hour_value col-3  col-md-2 col-lg-2">
                                    <div>
                                      <span>{gameTimeItem.time}</span>
                                    </div>
                                  </div>
                                  <div className="hour_detail_box col-6 col-md-8 col-lg-8">
                                    {/* <p>{gameTimeItem.message}</p> */}
                                  </div>
                                  <div className="order_box col-3 col-2 col-md-2 col-lg-2">
                                    {" "}
                                    <span></span>{" "}
                                  </div>
                                </div>
                              </div>
                            );
                          // return null;
                        })}


                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          }
          <Footer />
        </div>


      </div >
    </>
  );
};

export default GameTime;