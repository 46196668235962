//images
import one1logo from '../images/logo.png'
import footerlogo from '../images/footer-bg-1170.jpg'
import { Box, Typography } from '@mui/material';

//css
import '../css/golf-rtl.css'
import '../css/golfstyle.css'
import '../css/reset.css'
import 'reactjs-popup/dist/index.css'
import { useSelector } from "react-redux";

export default function Footer() {
    const textDocument = useSelector((state) => state.textDocument || {});

    return (
        <Box pb={5} mt={5}>
            <Box
                // pl={3}
                // pr={3}
                m={0}
                sx={{
                    // borderRadius: '10px',
                    overflow: 'hidden',
                }}
            // style={{ position: 'relative' }}
            >
                <img src={footerlogo} alt={footerlogo} className="buyNowImg" />
                <Box

                    m={0}
                    sx={{
                        overflow: 'hidden',
                        backgroundColor: '#333333',
                        paddingRight: '10px',
                        paddingLeft: '10px',

                        //  float:
                        //     textDocument.language != "Hebrew" ? 'left' : 'right'
                    }}

                >
                    <Typography sx={{
                        color: 'white',
                        marginTop: '5px',
                        float:
                            textDocument.language !== "Hebrew" ? 'right' : 'left'

                    }} mb={0}>
                        {textDocument.FooterAllRightsReserved}
                    </Typography>

                    <img src={one1logo} alt={one1logo} className="buyNowImg"
                        style={{ float: textDocument.language !== "Hebrew" ? 'left' : 'right',width:'150px',marginTop:'6px',marginBottom:'8px'}} />

                </Box>
            </Box>
        </Box>


    )
}


