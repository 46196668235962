import { useState, useEffect } from "react";
import moment from "moment";
import { axiosApiGet, axiosApiPost, axiosApiPut } from "../../network/api/RequestsApi";
import Marshal from "./Marshal";
import { useSelector } from "react-redux";
import { DayInWeek } from '../../Logic/dayInWeek'
import { useNavigate } from "react-router-dom";

const MarshalLogic = () => {

  const [entriesList, setEentriesList] = useState([{}, {}, {}, {}]);
  const [date, setDate] = useState(moment());
  const [seqPlayerRemark, setSeqPlayerRemark] = useState();
  const [remark, setRemark] = useState("");
  const [isOpenEditRemark, setIsOpenEditRemark] = useState(false);
  const [messagesError, setMessagesError] = useState("");
  const [selectedFriendsCode, setSelectedFriendsCode] = useState();
  const [isOpenAddFriendModal, setIsOpenAddFriendModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const envDefaults = useSelector((state) => state.envDefaults);
  const textDocument = useSelector((state) => state.textDocument || {});
  const user_type = useSelector((state) => state.detail?.user_type);

  const navigate = useNavigate();


  //Gets the list of customer
  const getEntriesList = async () => {
    setIsLoading(true)
    const res = await axiosApiGet(`/${envDefaults.entriesRoute}`, {
      event_date: moment(date).format("yyyy-MM-DD"),
    });
    if (res?.status !== 'ok') setEentriesList([]);
    else {

      res?.payload?.length >= 0 && setEentriesList(res?.payload);

    }
    setIsLoading(false)
  };

  //The getEntriesList function in loading the page and changing the date
  useEffect(() => {
    if (user_type !== 2)
      navigate("/main")

    getEntriesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envDefaults, date]);

  const editRemark = async () => {

    const res = await axiosApiPut(`/${envDefaults.entriesRoute}/${seqPlayerRemark}`, { remark });
    if (res?.status === "ok") {
      setIsOpenEditRemark(false)
      handleRefresh()
    } else {
      setMessagesError(textDocument.MessagesErrorRemark)
      setTimeout(() => {
        setRemark()
        setMessagesError()
        setIsOpenEditRemark(false)
      }, 3000);
    }
  };

  const addFriends = async () => {

    const res = await axiosApiPost(`/${envDefaults.entryRoute}`, { customer_code: selectedFriendsCode });
    if (res?.status === "ok") {
      setIsOpenAddFriendModal(false)
      handleRefresh()
      setSelectedFriendsCode()

    } else {
      setMessagesError(textDocument.FailedAddFriend)
      setTimeout(() => {
        setMessagesError('')
        setSelectedFriendsCode()
        setIsOpenAddFriendModal(false)
      }, 6000);
    }
  };
  //Clicking the refresh button
  const handleRefresh = async () => {
    getEntriesList()
  };

  return (
    <Marshal
      date={date}
      setDate={setDate}
      day={DayInWeek(date, textDocument)}
      entriesList={entriesList}
      handleRefresh={handleRefresh}
      setSeqPlayerRemark={setSeqPlayerRemark}
      remark={remark}
      setRemark={setRemark}
      isOpenEditRemark={isOpenEditRemark}
      setIsOpenEditRemark={setIsOpenEditRemark}
      messagesError={messagesError}
      editRemark={editRemark}
      isOpenAddFriendModal={isOpenAddFriendModal}
      setIsOpenAddFriendModal={setIsOpenAddFriendModal}
      selectedFriendsCode={selectedFriendsCode}
      setSelectedFriendsCode={setSelectedFriendsCode}
      addFriends={addFriends}
      isLoading={isLoading}
      setMessagesError={setMessagesError}
    />
  );
};

export default MarshalLogic;


