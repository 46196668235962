import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Footer from '../Footer'
import Header from '../Header/header'
import { heIL } from '@mui/material/locale';
import { axiosApiGet, axiosApiPost } from '../../network/api/RequestsApi'
import { useDispatch, useSelector } from "react-redux";
import Modal from '../UI-Comps/Modal';
import { setTextPopup } from "../../redux/login/LoginAction";
import { Box } from '@mui/system';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '2rem',

    },
    title: {
        fontSize: '1.5rem',
        color: "#003B36",
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '30rem',
        marginTop: '3%'
    },
    input: {
        marginBottom: '1rem',
        backgroundColor:'white'

    },
}));


const ResetPassword = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const reset_password_token = location.pathname.match(/\/([^/]+)$/)[1].replace(/[^a-zA-Z0-9]/g, '');

    const [validit, setValidit] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState({
        new_password: '',
        password_verification: '',
    });

    const envDefaults = useSelector((state) => state.envDefaults);
    const textDocument = useSelector((state) => state.textDocument || {});
    const textPopup = useSelector((state) => state.textPopup);

    const fields = [

        {
            label: textDocument.NewPassword,
            type: 'password',
            required: true,
            state: 'new_password',
        },
        {
            label: textDocument.PasswordVerification,
            type: 'password',
            required: true,
            state: 'password_verification',
        },
    ];

    function getPasswordRequirements(value) {
        return value === true ? 1 : value;
    }
    useEffect(() => {
        async function authResetPasswordToken() {
            const res = await axiosApiGet(`/${envDefaults.usersRoute}/${envDefaults.passwordRoute}/${reset_password_token}`);
            if (res?.status === "failed") navigate('/error')
            else {
                const res = await axiosApiGet(`/${envDefaults.public}/${envDefaults.passwordValidityRoute}`);
                if (res?.payload) {
                    setValidit({
                        digit: getPasswordRequirements(res?.payload[0].digit),
                        lowercase: getPasswordRequirements(res?.payload[1].lowercase),
                        uppercase: getPasswordRequirements(res?.payload[2].uppercase),
                        special: getPasswordRequirements(res?.payload[3].special),
                        minimum: getPasswordRequirements(res?.payload[4].minimum)
                    })
                }
            }
        }

        if (reset_password_token) {
            authResetPasswordToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const passwordRegex = `^(?=.*\\d){${validit?.digit},}(?=.*[a-z]){${validit?.lowercase},}(?=.*[A-Z]){${validit?.uppercase},}(?=.*[^a-zA-Z0-9]){${validit?.special},}.{${validit?.minimum
            },}$`;
        const regex = new RegExp(passwordRegex);

        const mandatoryFields = fields.filter((field) => field.required);

        const emptyFields = mandatoryFields.filter((field) => !formData[field.state]);

        if (emptyFields.length > 0) {
            dispatch(setTextPopup(textDocument.FieldsRequired));
            setShowPopup(true);
            return;
        } else if (formData.new_password !== formData.password_verification) {
            dispatch(setTextPopup(textDocument.PasswordsDoNotMatch));
            setShowPopup(true);
            return;

        } else if (!regex.test(formData.new_password)) {
            // eslint-disable-next-line no-template-curly-in-string
            dispatch(setTextPopup(textDocument.ErrorNewPasswordChange.replace('${minimum}', validit?.minimum)));
            setShowPopup(true);
            return;

        } else {
            const requestBody = {
                reset_password_token: reset_password_token,
                new_password: formData.new_password,
                password_verification: formData.password_verification,
            };



            const res = await axiosApiPost(`/${envDefaults.passwordUpdateRoute}`, requestBody
            );

            if (res?.status === "ok") {
                dispatch(setTextPopup(textDocument.PasswordUpdateSuccess));
            } else {
                dispatch(setTextPopup(textDocument[res?.error_message] ?? textDocument?.ServerProblem))
            }
            setShowPopup(true);
        }
    };
    const goLogin = () => {
        navigate("/login");
    };
    return (
        <>
            <Modal
                show={showPopup}
                onCancel={(e) => {
                    e.preventDefault();
                    textPopup === textDocument.PasswordUpdateSuccess && goLogin();
                    setShowPopup(false)
                }}
                onSubmit={(e) => {
                    e.preventDefault();
                    textPopup === textDocument.PasswordUpdateSuccess && goLogin();

                }}
                modalButtonLeft={textPopup === textDocument.PasswordUpdateSuccess ? textDocument.UpdatePasswordButtonBack : textDocument.modalButtonLeft}
            >
                <p>{textPopup}</p>
            </Modal>
            <Header />

            <div className="container " >
                <div className='bg-image' >
                    <div className={classes.container} >

                        <div className={classes.title}>
                            {textDocument.ResetPasswordTitle}
                        </div>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            {fields.map((field) => (
                                <TextField
                                    key={field.state}
                                    placeholder={field.label}
                                    type={field.type}
                                    required={field.required}
                                    className={classes.input}
                                    value={formData[field.state]}
                                    name={field.state}
                                    onChange={handleChange}
                                    local={heIL}
                                    color={"success"}
                                    size='small'
                                />
                            ))}
                            <Box display="-webkit-flex" justifyContent="space-around" marginBottom='20%'>

                                <Link to="/Login" >
                                    <Button
                                        variant="contained"
                                        size="large"
                                        // onClick={handleSubmit}
                                        sx={{
                                            backgroundColor: "#003B36",
                                            color: "white",
                                            "&:hover": {
                                                backgroundColor: "#228c3d",
                                            }
                                        }}
                                    >
                                        {textDocument.UpdatePasswordButtonBack}
                                    </Button>
                                </Link>
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={handleSubmit} sx={{
                                        backgroundColor: "#003B36",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor: "#228c3d",
                                        }
                                    }}
                                >
                                    {textDocument.UpdatePasswordButton}
                                </Button>

                            </Box>

                        </form>
                    </div>
                </div>
                <Footer />
            </div>

        </>
    );
};

export default ResetPassword;