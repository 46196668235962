import magnify from "../../../images/magnify.png";

import { useEffect, useState } from "react";
import { axiosApiGet } from "../../../network/api/RequestsApi";
import { useSelector } from "react-redux";

const AddFriendEntranceContainer = ({ event_date, setFriendDetails, newOrder }) => {

  const [friendCode, setFriendCode] = useState('');
  const [friendName, setFriendName] = useState("");
  const [wrongFriendCode, setWrongFriendCode] = useState("");
  const [friendsList, setFriendsList] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  const envDefaults = useSelector((state) => state.envDefaults);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const wcCode = params.get('wcCode');
  const time = params.get('selectedTime');
  const textDocument = useSelector((state) => state.textDocument || {});
  const user_type = useSelector((state) => state.detail?.user_type);
  useEffect(() => {
    const listFriendsDisplay = async () => {
      const res = await axiosApiGet(`/${envDefaults.usersRoute}`);

      if (res?.payload)
        setFriendsList(res.payload);
    };

    user_type === 2 && listFriendsDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_type]);

  const filteredFriendsList = friendsList?.length > 0 &&
    friendsList?.filter(friend => {
      const nameMatches = friend?.user_name?.toLowerCase()?.includes(searchInput?.toLowerCase());
      const codeMatches = friend?.user_code?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase());
      const latinNameMatches = friend?.user_latin_name?.toLowerCase()?.includes(searchInput?.toLowerCase());

      return nameMatches || codeMatches || latinNameMatches;
    });

  const verifyFriendCodeAndGetName = async (friend_code) => {

    let friend_code_temp
    if (typeof friend_code === 'string' || typeof friend_code === 'number')
      friend_code_temp = friend_code
    else
      friend_code_temp = friendCode


    const sameFriend = newOrder?.players && newOrder?.players?.find(
      player => player.user_code === parseInt(friend_code_temp) || player.user_code === (friend_code_temp));
    if (sameFriend) {
      setWrongFriendCode(textDocument.PlayerSelected);
    }
    else {

      const res = await axiosApiGet(`/${envDefaults.checkFriendRoute}`, {
        user_code: (friend_code_temp),
        event_date: event_date,
        start_time: time,
        wc_code: wcCode === "par18" ? 1 : 2
      });

      if (res?.payload) {
        let name =
          res.payload?.customer_latin_name ?
            textDocument.language !== "Hebrew" ? res.payload.customer_name : res.payload.customer_latin_name
            :
            res.payload.customer_name
        setFriendName(name);
        setFriendDetails({ name: name, code: (friend_code_temp) });
      } else {
        setWrongFriendCode(textDocument[res?.error_message] ?? textDocument?.WrongId);
      }
    }

  };




  return (
    <div>


      <div className="modal-body">
        <div className="form">
          <div className="form_group">
            <div>
              <label
                htmlFor="ufld:GOLFER_CODE.DUMMY.MAALE"
                id="ufld:HEAD_TEXT.DUMMY.MAALE"
              >
                {" "}
                {textDocument.EnterPlayerSearch}{" "}
              </label>
              {user_type === 1 ?
                <div>
                  <div className="input_group">
                    <input
                      id="ufld:GOLFER_CODE.DUMMY.MAALE"
                      value={friendCode}
                      className="input-numner"
                      onChange={(e) => {
                        setFriendCode(e.target.value);
                        setWrongFriendCode("");
                        setFriendName('')
                      }}
                    />
                    <span>
                      <img
                        src={magnify}
                        alt="search"
                        className="magnify"
                        onClick={friendCode ? verifyFriendCodeAndGetName : undefined}

                      />
                    </span>{" "}
                  </div>


                </div> :
                <div id="input_container">
                  <input
                    type="text"
                    // placeholder={textDocument?.chooseFriend}
                    value={searchInput}
                    onChange={e => {
                      const selectedFriend = friendsList?.length > 0 && friendsList?.find(
                        (friend) => friend?.user_code?.toString() === e.target?.value
                      );
                      setSearchInput(selectedFriend?.user_name ?? e.target.value)
                      setFriendCode(e.target.value);
                      setWrongFriendCode("");
                      setFriendName('')
                      if (selectedFriend) {
                        verifyFriendCodeAndGetName(e.target.value)
                        // setSelectedFriendsCode(e.target.value)
                      }
                    }}
                    list="browsers"
                  />
                  <datalist id="browsers">
                    {filteredFriendsList?.length > 0 && filteredFriendsList?.map((c, key) => (
                      <option key={key} value={c.user_code}>
                        {
                          textDocument.language !== "Hebrew" ? c.user_name : c.user_latin_name ?? c.user_name

                        }
                      </option>
                    ))}
                  </datalist>

                </div >

              }
              <div>{wrongFriendCode}</div>
              <div className="form_group">
                <div className="golfer_mate_name">
                  {friendName !== -1 && friendName}

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default AddFriendEntranceContainer;
