import { useState, useEffect } from "react";
import moment from "moment";
import { axiosApiGet } from "../../network/api/RequestsApi";
import GuidesDiary from "./GuidesDiary";
import {  useSelector } from "react-redux";
import { DayInWeek } from '../../Logic/dayInWeek'
import { useNavigate } from "react-router-dom";


const GuidesDiaryLogic = () => {

    const initialOrderList = [{}, {}, {}, {}]

    const [date, setDate] = useState(moment());
    const [allHours, setAllHours] = useState(initialOrderList);
    const [isLoading, setIsLoading] = useState(true);

    const textDocument = useSelector((state) => state.textDocument || {});
    const envDefaults = useSelector((state) => state.envDefaults);
    const teacher = useSelector((state) => state.detail?.teacher || false);

    const navigate = useNavigate();

    //get apprentice list
    const getApprenticeList = async (hours) => {

        setIsLoading(true);
        const res = await axiosApiGet(
            `/${envDefaults.teachersScheduleRoute}`, { event_date: moment(date).format("yyyy-MM-DD") }
        );

        if (res?.payload) {
            await unificationHoursWithApprentice(hours, res?.payload)

        }
        // else {
        //     await unificationHoursWithApprentice(hours, res?.payload)
        // }
        setIsLoading(false);

    };

    const unificationHoursWithApprentice = async (largeArray, smallArray) => {
        const mergedArray = largeArray.map((hour) => {
            const matchingHours = smallArray?.filter((item) => moment(item.hour, "H").isSame(moment(hour.hour, "HH:mm"), "hour"));
            if (matchingHours.length > 0) {
                return {
                    ...hour,
                    total_people: matchingHours[0]?.total_people,
                    apprentice: matchingHours,
                };
            } else {
                return hour;
            }
        });
        mergedArray.length > 0 && setAllHours(mergedArray)
    };

    useEffect(() => {
        if (!teacher)
        navigate("/main")
  
        hoursList();

        // loadAreaDataLogic(dispatch, envDefaults, user_type,preorder,wcCode,user_type === 2 ? true : false)


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]); // Remove hoursList from the dependency array



    //create hours list
    const hoursList = async () => {
        const hours = []
        for (let hour = 6; hour <= 18; hour++) {
            hours.push({ hour: moment({ hour }).format('HH:mm') })
        }

        setAllHours(hours)
        hours && getApprenticeList(hours)
    };

    return (
        <GuidesDiary
            date={date}
            setDate={setDate}
            day={DayInWeek(date, textDocument)}
            apprenticeList={allHours}
            isLoading={isLoading}
        />
    );
};

export default GuidesDiaryLogic;