import { useState } from "react";


import "../../css/golf-rtl.css";
import "../../css/golfstyle.css";
import "../../css/reset.css";
import "reactjs-popup/dist/index.css";

import PreviousOrNext from '../PreviousOrNext'
import PopFriendImages from "./PopMarshal/PopFriendImages";
import Modal from "../UI-Comps/Modal";
import Header from "../Header/header";
import Footer from "../Footer";
import PopAddFriemds from "./PopMarshal/PopAddFriemds";
import { useSelector } from "react-redux";
import moment from "moment";
import { Box, CircularProgress } from "@mui/material";
import { axiosApiGet } from "../../network/api/RequestsApi";
// import PreviousOrNext from "../Logic/PreviousOrNext";

const Marshal = (props) => {
  const {
    date,
    setDate,
    day,
    entriesList,
    handleRefresh,
    setSeqPlayerRemark,
    remark,
    setRemark,
    isOpenEditRemark,
    setIsOpenEditRemark,
    messagesError,
    editRemark,
    isOpenAddFriendModal,
    setIsOpenAddFriendModal,
    selectedFriendsCode,
    setSelectedFriendsCode,
    addFriends,
    isLoading,
    setMessagesError
  } = props;

  const textDocument = useSelector((state) => state.textDocument || {});
  const envDefaults = useSelector((state) => state.envDefaults);


  const [isOpenFriendImages, setIsOpenFriendImages] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState("");
  const [selectedUserImg, setSelectedUserImg] = useState("");
  const [selectedEnterImg, setSelectedEnterImg] = useState("");
  const [loader, setLoader] = useState([false, false]);

  //open popup Image
  const handleImageClick = async (userName, code, sequence_number) => {
    
    const res = await axiosApiGet(`/${envDefaults.customerPictureRoute}`, { customer_code: code });
    setLoader(prevLoader => [true, ...prevLoader.slice(1)]);

    if (res?.payload?.picture) {
      let convertUserName = res.payload?.picture?.replace(/^data:image\/\w+;base64,/, '');
      setSelectedUserImg(convertUserName)

    }
    else if (res?.status === "ok" || res?.status === "failed")
      setSelectedUserImg();

    const resEnterImg = await axiosApiGet(`/${envDefaults.enterPictureRoute}`, { sequence_number: sequence_number });
    setLoader(prevLoader => [prevLoader[0], true]);

    if (resEnterImg?.payload) {
      let convertUserName = resEnterImg.payload?.replace(/^data:image\/\w+;base64,/, '');
      setSelectedEnterImg(convertUserName)
    }
    else if (resEnterImg?.status === "ok" || resEnterImg?.status === "failed")
      setSelectedEnterImg();

    setIsOpenFriendImages(true);
    setSelectedUsername(userName);
  };

  const propsModal = {
    left: "30%",
    width: "20%",
    right: "40%"
  };
  const propsModalButton = {
    modalButtonLeft: textDocument.modalButtonLeft,
    modalButtonRight: textDocument.modalButtonRight,
  };
  return (
    <>
      <Header Login={"Login"} Main={"Main"} />

      <div className="entrance-control">
        <Modal
          // {...propsModal}
          show={isOpenAddFriendModal}
          onCancel={() => {
            setIsOpenAddFriendModal(false)
            setMessagesError()
          }}
          onSubmit={(e) => {
            e.preventDefault()

            addFriends()
          }}
          modalButtonLeft={selectedFriendsCode && textDocument.modalButtonLeft}
          modalButtonRight={textDocument.modalButtonRight}

          header={textDocument.AddFriend}
        >
          <PopAddFriemds setSelectedFriendsCode={setSelectedFriendsCode} />
          <div >{messagesError}</div>
        </Modal>

        <Modal
          {...propsModal}
          show={isOpenFriendImages}
          onCancel={() => {
            setIsOpenFriendImages(false)
          }}
          onSubmit={(e) => {
            selectedFriendsCode && e.preventDefault()
          }}
          header={selectedUsername}
        >
          <PopFriendImages selectedUserImg={selectedUserImg} selectedEnterImg={selectedEnterImg} loader={loader}/>
        </Modal>


        <Modal
          {...propsModalButton}
          show={isOpenEditRemark}
          onCancel={() => setIsOpenEditRemark(false)}
          onSubmit={(e) => {
            e.preventDefault()
            editRemark()
          }}
          header={textDocument.HeaderRemark}
          width="40%"
          right="30%"
        >
          <div className="forget-password">
            <label htmlFor="remark">  {textDocument.ChangingRemark}: </label>
            <input
              value={remark}
              id="remark"
              type="text"
              name="remark"
              onChange={(e) => {
                setRemark(e.target.value);
              }}
            />
            <label>{messagesError}</label>
          </div>
        </Modal>

        <div className="container - EntranceControl ">
          <div className="form_title">
            {" "}
            <span>{textDocument.EnterancesTracking}</span>{" "}
          </div>
          {/* <!-- End form_title --> */}
          <PreviousOrNext date={moment(date).format("DD/MM/YYYY")} day={day} setDate={setDate} marshal={true} />
          {isLoading &&
            <Box>
              <CircularProgress />
            </Box>}
          {!isLoading &&
            <div>
              <div className=" buttons-entrance-control">
                <div className=" pull-opp-dir ">
                  <button
                    type="button"
                    id=""
                    className="btn btn-success"
                    onClick={handleRefresh}
                  >
                    <span className="refresh-icon"></span> {textDocument.Refresh}
                  </button>
                  <button
                    data-toggle="modal"
                    data-target="#PopUpModal"
                    type="button"
                    id=""
                    className="btn btn-success btn-reg-side "
                    onClick={() => setIsOpenAddFriendModal(true)}
                  >
                    {textDocument.AddGolfer}
                    <span className="plus-icon"></span>
                  </button>
                </div>
              </div>
              <div className="entrance-control-table">
                <div className="row control-head">

                  <div className="col"> #</div>
                  <div className="col"> {textDocument.EnterPicGolfer} </div>
                  <div className="col"> {textDocument.Time} </div>
                  <div className="col"> {textDocument.Swipe}</div>
                  <div className="col">{textDocument.Source}</div>
                  <div className="col">{textDocument.Name}</div>
                  <div className="col">{textDocument.Accepted}</div>
                  <div className="col"> {textDocument.Subscription}</div>
                  <div className="col"> {textDocument.HeaderRemark}</div>

                </div>

                {entriesList.length > 0 && entriesList?.map((entranceItem, i) => (
                  <div key={i} className="row control-line">
                    <div className="col">{entranceItem?.line_nbr} </div>

                    <div className="col">

                      {entranceItem?.line_nbr !== undefined && <div className="camera-icon"
                        onClick={() => handleImageClick(

                          entranceItem?.player_latin_name ?
                            textDocument.language !== "Hebrew" ? entranceItem.player_name : entranceItem.player_latin_name
                            :
                            entranceItem?.player_name,
                          entranceItem?.customer_code, entranceItem?.sequence_number)}>

                      </div>}
                    </div>

                    <div className="col"> {entranceItem?.start_time}</div>
                    <div className="col"> {entranceItem?.swipe_point}</div>
                    <div className="col"> {entranceItem?.input_code}</div>

                    <div className="col"> {entranceItem?.player_latin_name ?
                      textDocument.language !== "Hebrew" ? entranceItem.player_name : entranceItem.player_latin_name
                      :
                      entranceItem?.player_name
                    }

                    </div>
                    <div className="col">
                      <span
                        className={
                          entranceItem?.approved === true ? "approved-icon" :
                            "forbidden-icon"
                        }
                      ></span>{" "}
                    </div>
                    <div className="col"> {entranceItem?.user_type}</div>

                    <div className="col fake-link"
                      onClick={(e) => {
                        setIsOpenEditRemark(true)
                        setSeqPlayerRemark(entranceItem?.seq_number)
                      }}>
                      {entranceItem?.remark ? entranceItem?.remark : "---"}
                    </div>

                  </div>
                ))}
              </div> </div>
          }
          <Footer />
        </div>

      </div >

    </>
  );
};
export default Marshal;