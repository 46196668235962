import axios from "axios";

const axiosApi = async ({ url, method, body, navigate }) => {
  let status = null;
  const headers = {
    'Authorization': `Bearer ${localStorage?.token?.replace(/['"]+/g, '')}`
  };

  try {
    // await axios[method](
    //   url,
    //   method === "get" 
    //     ? { params: body, headers: headers }
    //     : method === "delete" 
    //       ? { data: body, headers: headers } // send body as data for DELETE requests
    //       : { data: body, headers: headers }, // for other methods
    // );
    await axios[method](url,
      method === "get"
        ? { params: body, headers: headers }
        : method === "delete"
          ? { data: body, headers: headers }
          :
          body,
      { headers: headers }
    )


      .then((response) => {

        status = response?.data || true;
      })
      .catch(function (error) {

        // if (error?.response?.data.message === "Invalid Token") {
        //   status = false
        //   window.location.href = '/Login'
        // }
        // else status = error?.response?.data || false;

        status = error?.response?.data || false;
      });
  } catch (error) {

    status = false;
  }
  return status;

};


export { axiosApi };

// const headers = {
//   Authorization: `Bearer ${localStorage.token}`,
//   "Content-Type": "application/json;",
// };