

import "../../css/golf-rtl.css";
import "../../css/golfstyle.css";
import "../../css/reset.css";
import "reactjs-popup/dist/index.css";
import Footer from "../Footer";
import Header from "../Header/header";
import { makeStyles } from '@mui/styles';
import magnify from '../../images/magnify.png'

import { useState } from "react";
import Modal from "../UI-Comps/Modal";
import AddFriendEntrance from "./AddFriendEntrance/AddFriendEntranceContainer";
import { setTextPopup } from "../../redux/login/LoginAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '0rem',

  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100rem',
  },


}));
const GameOrderConfirmation = (props) => {
  const classes = useStyles();

  const { seconds, minutes, goBackToGameTime, endOrder, newOrder, setNewOrder, day, wcCode, showTimerPopup, setShowTimerPopup, removeFriend, showWrongFriendNumber, setShowWrongFriendNumber, isRemoveFriend, ordersList, newArray, setNewArray } = props;
  const user_type = useSelector((state) => state.detail?.user_type);
  const teacher = useSelector((state) => state.detail?.teacher || false);
  const user_code = useSelector((state) => state?.detail?.user_code || 1);

  const [showMessage, setShowMessage] = useState(false);
  const [showAddPlayerPopup, setShowAddPlayerPopup] = useState(false);
  const [newFriendDetails, setNewFriendDetails] = useState();
  const [removeFriendCode, setRemoveFriendCode] = useState();
  const [detailsRemoveFriendSelected, setDetailsRemoveFriendSelected] = useState({});

  const textDocument = useSelector((state) => state.textDocument || {});
  const textPopup = useSelector((state) => state.textPopup);
  const dispatch = useDispatch();

  //Add Friend 
  const handleAddFriendEntrance = () => {

    setShowAddPlayerPopup(false);
    if (!newFriendDetails) return;
    const newOrderTmp = { ...newOrder };
    const newFriendDetailsTmp = {
      player_name: newFriendDetails.name,
      user_code: newFriendDetails.code,
      new_friend: true,
      car: false,
    };
    newOrderTmp.players.push(newFriendDetailsTmp);
    setNewOrder(newOrderTmp);
  };

  //Add Friend Popup
  const handleAddPlayerClick = () => {

    if (newOrder.players.length >= 4) {
      dispatch(setTextPopup(textDocument.MaxPlayers))
      return setShowMessage(true);
    }
    else
      setShowAddPlayerPopup((showAddPlayerPopup) => !showAddPlayerPopup);
  };
  //Add Friend Popup
  const checkRemoveFriendCode = (code) => {
    if(user_type===2)
    removeFriend(detailsRemoveFriendSelected?.new_friend, detailsRemoveFriendSelected?.user_code, detailsRemoveFriendSelected?.event_date, detailsRemoveFriendSelected?.wc_code, detailsRemoveFriendSelected?.start_time, detailsRemoveFriendSelected.player_name)

    if (removeFriendCode?.toString() === detailsRemoveFriendSelected?.user_code?.toString())
      removeFriend(detailsRemoveFriendSelected?.new_friend, detailsRemoveFriendSelected?.user_code, detailsRemoveFriendSelected?.event_date, detailsRemoveFriendSelected?.wc_code, detailsRemoveFriendSelected?.start_time, detailsRemoveFriendSelected.player_name)
    else
      dispatch(setTextPopup(textDocument['The friemd code is not compatible']))

  };

  const countCar = () => newOrder?.players?.reduce((acc, player) => acc + (player.car === true), 0);

  //update Array New Order - car/stick
  async function updateArrayNewOrder(carOrStick, value, code, stickName, stickValue, stickDefault) {
    let newState = {
      ...newOrder, players: newOrder.players?.map(obj => {
        if (obj?.user_code === code) {
          if (carOrStick === "car") {
            const count = countCar()
            if (count > 1 && value) {
              dispatch(setTextPopup(textDocument["You can order up to two cars per game"]))
              setShowMessage(true);
              return { ...obj, car: false };
            }
            else
              return { ...obj, car: value };

          }
          else if (carOrStick === "stick" && stickDefault) {

            if (stickValue === false)
              return { ...obj, stick: value };
            else
              return { ...obj, stick: value, stick_gender: "man", stick_hand: "right", stick_kind: "graphite" };

          }
          else if (carOrStick === "stick")
            return { ...obj, [stickName]: stickValue };

        }
        else {
          return obj;
        }
        return obj;

      })
    };
    setNewOrder(newState);
  };

  //Min & Max Friends
  const handleEndOrder = () => {

    if (user_type === 2 || (user_type === 1 && ordersList) || (user_type === 1 && isRemoveFriend.length !== newOrder.players.length)) {

      if (newOrder.players.length > 4 || newOrder.players.length < 1) {
        dispatch(setTextPopup(newOrder.players.length < 1 ? textDocument.MinimumPlayers.replace('${minimum}', 1) : textDocument.MaxPlayers))
        return setShowMessage(true);
      }
    }
    else
      if (newOrder.players.length < 2 || newOrder.players.length > 4) {
        dispatch(setTextPopup(newOrder.players.length < 2 ? textDocument.MinimumPlayers.replace('${minimum}', 2) : textDocument.MaxPlayers))
        return setShowMessage(true);
      }
    endOrder(newFriendDetails);
  };

  return (
    <>
      <Header Login={"Login"} OrderList={"OrderList"} GameOrder={"GameOrder"}
        EntranceControl={user_type === 2 && "EntranceControl"} GuidesDiary={teacher && "GuidesDiary"} Feedback={"Feedback"} />
      {/* message */}
      <Modal
        show={showTimerPopup}
        onSubmit={() => setShowTimerPopup(false)}
        onCancel={(e) => {
          e.preventDefault();
          goBackToGameTime()
        }}
        modalButtonLeft={textDocument.modalButtonLeft}
      >
        <p>{textPopup}</p>
      </Modal>
      {/* message */}
      <Modal
        show={showMessage}
        onSubmit={() => setShowMessage(false)}
        onCancel={(e) => {
          e.preventDefault();
          setShowMessage(false)
        }}
        modalButtonLeft={textDocument.modalButtonLeft}
      >
        <p>{textPopup}</p>
      </Modal>
      {/* add friend*/}
      <Modal
        show={showAddPlayerPopup}
        onCancel={() => setShowAddPlayerPopup(false)}
        onSubmit={(e) => {
          e.preventDefault();
          handleAddFriendEntrance()
        }}
        header={textDocument.AddPlayer}
        modalButtonLeft={newFriendDetails && textDocument.modalButtonLeft}
        modalButtonRight={textDocument.modalButtonRight}
      >
        <AddFriendEntrance event_date={newOrder?.event_date} setFriendDetails={setNewFriendDetails} newOrder={newOrder} />
      </Modal>

      {/* remove friend*/}
      <Modal
        show={showWrongFriendNumber}
        onCancel={() => {
          if (newArray) {
            setNewOrder(newArray);
            dispatch(setTextPopup(textDocument["The player has been successfully removed"]))
          }
          setTimeout(() => {
            setShowWrongFriendNumber(false)
            dispatch(setTextPopup())
            setRemoveFriendCode();
            setNewArray()
          }, 2000);

        }}
        onSubmit={(e) => {
          e.preventDefault();
          setRemoveFriendCode();
          dispatch(setTextPopup())
          setShowWrongFriendNumber(false)
        }}
        modalButtonLeft={newArray && textDocument.modalButtonLeft}
      >
        <div>
          <label
            htmlFor="ufld:GOLFER_CODE.DUMMY.MAALE"
            id="ufld:HEAD_TEXT.DUMMY.MAALE"
          >
            {" "}
            {textDocument['Enter a member code you want to remove']}{" "}
          </label>
          <div className="input_group">
            <input
              id="ufld:GOLFER_CODE.DUMMY.MAALE"
              value={removeFriendCode}
              className="input-numner"
              onChange={(e) => {

                dispatch(setTextPopup())
                setRemoveFriendCode(e.target.value);
              }}
            />
            <span>
              <img
                src={magnify}
                alt="search"
                className="magnify"
                onClick={() => {
                  dispatch(setTextPopup())

                  checkRemoveFriendCode();
                }}
              // onClick={checkRemoveFriendCode}
              />
            </span>{" "}
          </div>
        </div>
        <p>{textPopup}</p>
      </Modal >

      <div className="container">
        <div className={classes.container} >

          <form className={classes.form} >
            <div className="form_title">
              {" "}
              <div>
                <div id="ufld:TITLE_ORDER_GAME.DUMMY.MAALE">{textDocument.HeaderReserveATeeTime}</div>{" "}
                <p>{minutes}:{seconds}</p>
              </div>
            </div>


            <div className="order_panel">
              <div
                className={`DateCenter DateCenter-${textDocument.language !== "Hebrew" ? 'right' : 'left'
                  }`}
              >
                <div className="my_orders_panel_title_date pull-dir">
                  <span>{moment(newOrder.event_date).format("DD.MM.YY")}</span>{" "}
                </div>
                <div className="column" style={{ display: 'grid' }}>
                  <div className="my_orders_panel_title_box pull-dir">
                    <div className="my_orders_panel_title_line_1">
                      {" "}
                      <span>{day}</span>{" "}
                    </div>
                    <div className="my_orders_panel_title_line_2 ">
                      {" "}
                      <span>{newOrder.start_time}</span>{" "}
                    </div>
                  </div>
                  <div className="my_orders_panel_title_box pull-dir ">
                    <div className="my_orders_panel_title_line_1">
                      {" "}
                      <span>{`Pete Dye ${newOrder.wc_code === "1" ? "par18" : (newOrder.wc_code ? "par3" : (wcCode === "par18" ? "par18" : "par3"))} Holes` || "Pete Dye par18 Holes"}</span>

                    </div>

                  </div>
                </div>
              </div>
              <div className="Devider"></div>
              <div className="form_subtitle">
                {" "}
                <span id="ufld:TITLE_PARTICIPANT.DUMMY.MAALE">{textDocument?.Players}</span>{" "}
              </div>

              {newOrder?.players.length > 0 ? newOrder?.players?.map((item, i) => (

                <div key={user_type === 2 ? i : i + 1}>

                  {item?.player_name ? (
                    <div className={classes.form} >
                      <div className="orders_golfer_box" key={item?.player_name}>
                      <div style={{display:'flex',justifyContent:'space-between',marginTop:'1%',marginLeft:'1%',marginRight:'1%'}}>
                      <div className="my_orders_golfer_name">                       
                            <span id="ufld:CUST_NAME.GOLFER1.MAALE">
                              {item?.player_name_display || item?.player_latin_name_display ?
                                textDocument.language !== "Hebrew" ? item.player_name_display : item.player_latin_name_display
                                :
                                (item?.player_name ?? item?.player_latin_name)}
                            </span>
                        </div>
                      {((user_type === 2) || (i !== 0 &&
                          (ordersList || (item?.user_code?.toString() !== user_code?.toString() && item?.new_friend)))) &&
                          < div id="remove_golfer_btn" style={{
                            // display: 'flex',
                            // justifyContent: 'flex-end',
                            // marginTop: '2%',
                            // marginLeft: '0.9%'
                          }}>
                            {!item?.arrived &&

                            <button

                              type="button"
                              id="ufld:PB_CAR.GOLFER1.MAALE"
                              className="btn btn-sm-btn-sm btn-default-remove"
                              onClick={() => {
                                dispatch(setTextPopup())
                                user_type === 2 ?   removeFriend(item?.new_friend, item.user_code, newOrder.event_date, newOrder.wc_code, newOrder.start_time)
                                : setShowWrongFriendNumber(true);
                                setDetailsRemoveFriendSelected({
                                  new_friend: item?.new_friend, user_code: item.user_code, event_date: newOrder.event_date, wc_code: newOrder.wc_code, start_time: newOrder.start_time
                                  , player_name:
                                    item?.player_latin_name ?
                                      textDocument.language !== "Hebrew" ? item.player_name : item.player_latin_name
                                      :
                                      item?.player_name
                                })
                              }}
                            >
                              {textDocument.Remove}
                            </button>}

                          </div>
                        }
                       </div>
                        {/* {((user_type === 2) || (i !== 0 &&
                          (ordersList || (item?.user_code?.toString() !== user_code?.toString() && item?.new_friend)))) &&
                          < div id="remove_golfer_btn" style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '2%',
                            marginLeft: '0.9%'
                          }}>
                            {!item?.arrived &&

                            <button

                              type="button"
                              id="ufld:PB_CAR.GOLFER1.MAALE"
                              className="btn btn-sm-btn-sm btn-default-remove"
                              onClick={() => {
                                dispatch(setTextPopup())
                                user_type === 2 ?   removeFriend(item?.new_friend, item.user_code, newOrder.event_date, newOrder.wc_code, newOrder.start_time)
                                : setShowWrongFriendNumber(true);
                                setDetailsRemoveFriendSelected({
                                  new_friend: item?.new_friend, user_code: item.user_code, event_date: newOrder.event_date, wc_code: newOrder.wc_code, start_time: newOrder.start_time
                                  , player_name:
                                    item?.player_latin_name ?
                                      textDocument.language !== "Hebrew" ? item.player_name : item.player_latin_name
                                      :
                                      item?.player_name
                                })
                              }}
                            >
                              {textDocument.Remove}
                            </button>}

                          </div>
                        }
                        <div className="orders_golfer_name_box">

                          <div className="my_orders_golfer_name">
                            {" "}

                            <span id="ufld:CUST_NAME.GOLFER1.MAALE">
                              {item?.player_name_display || item?.player_latin_name_display ?
                                textDocument.language !== "Hebrew" ? item.player_name_display : item.player_latin_name_display
                                :
                                (item?.player_name ?? item?.player_latin_name)}
                            </span>{" "}
                          </div>
                        </div> */}
                        
                        {(user_type === 2 || (ordersList || (item?.user_code?.toString() === user_code?.toString()) || item?.new_friend)) &&
                          <>
                            <div className="orders_golfer_car_box">

                              {item?.car ? (
                                <div id="remove_golfer_btn" style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  marginTop: '2%'
                                }}>
                                  <button
                                    type="button"
                                    id="ufld:PB_CAR.GOLFER1.MAALE"
                                    className="btn btn-sm btn-default-remove"
                                    onClick={() => {
                                      updateArrayNewOrder("car", false, item.user_code)
                                    }}
                                  >
                                    {textDocument.Remove}
                                  </button>

                                </div>
                              )
                                : (
                                  ""
                                )}
                              <div
                                onClick={() => {
                                  updateArrayNewOrder("car", true, item?.user_code)
                                }}
                                className="order-car-link">
                                <div className="add-car"></div>
                                <div
                                  id="ufld:TITLE_ORDER_CAR.GOLFER1.MAALE"
                                  className="OrderCar"

                                >
                                  {textDocument.ReserveCart}
                                </div>
                              </div>

                              {item?.car ? (
                                <div
                                  id="ufld:CAR_MESSAGE.GOLFER1.MAALE"
                                  className="CarAdded"
                                >
                                  {textDocument.CartAdd}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="orders_golfer_club_box">
                              <div>
                                {item?.stick && (
                                  <div id="remove_golfer_btn" style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '2%'
                                  }}>
                                    <button
                                      type="button"
                                      id="ufld:PB_PB_STICK.GOLFER1.MAALE"
                                      className="btn btn-sm btn-default-remove"
                                      onClick={() => {
                                        updateArrayNewOrder("stick", false, item.user_code, "", false, "stickDefault")

                                      }}
                                    >
                                      {textDocument.Remove}
                                    </button>
                                  </div>
                                )}
                                <div
                                  onClick={() => {
                                    updateArrayNewOrder("stick", true, item.user_code, "", 1, "stickDefault")

                                  }}
                                  className="order-car-link"
                                >
                                  <div className="add-golf"></div>
                                  <div
                                    id="ufld:TITLE_ORDER_BAT.GOLFER1.MAALE"
                                    className="OrderGolf"
                                  >
                                    {textDocument.ReserveGolf}{" "}
                                  </div>
                                </div>
                              </div>

                              {item?.stick ? (
                                <div className="orders_golfer_club_type row ">

                                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 orders_golfer_gender_box ">
                                    <div id="ufld:TITLE1.GOLFER1.MAALE">{textDocument.SelectGender}</div>

                                    <div className="" data-toggle="buttons">



                                      <label
                                        className={`btn btn-primary ${item.stick_gender === "woman" && "active"}`}
                                        onClick={() => {
                                          updateArrayNewOrder("stick", true, item.user_code, "stick_gender", "woman")

                                        }}>

                                        {textDocument.woman}{" "}
                                      </label>
                                      <label
                                        className={`btn btn-primary ${item?.stick_gender !== "woman" && "active"}`}
                                        onClick={() => {
                                          updateArrayNewOrder("stick", true, item.user_code, "stick_gender", "man")
                                        }}
                                      >
                                        {textDocument.man}{" "}
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 orders_golfer_mtrl_box">
                                    <div id="ufld:TITLE2.GOLFER1.MAALE">{textDocument.SelectClub} </div>
                                    <div className="" data-toggle="buttons">


                                      <label
                                        className={`btn btn-primary ${item.stick_kind === "iron" && "active"}`}

                                        onClick={() => {

                                          updateArrayNewOrder("stick", true, item.user_code, "stick_kind", "iron")

                                        }}>

                                        {textDocument.iron}{" "}
                                      </label>
                                      <label
                                        className={`btn btn-primary ${item?.stick_kind !== "iron" && "active"}`}

                                        onClick={() => {
                                          updateArrayNewOrder("stick", true, item.user_code, "stick_kind", "graphite")

                                        }}>

                                        {textDocument.graphite}{" "}
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 orders_golfer_hand_box">
                                    <div id="ufld:TITLE3.GOLFER1.MAALE">{textDocument.SelectHand}</div>

                                    <div data-toggle="buttons">

                                      <label
                                        className={`btn btn-primary ${item.stick_hand === "left" && "active"}`}
                                        onClick={() => {
                                          updateArrayNewOrder("stick", true, item.user_code, "stick_hand", "left")

                                        }}>

                                        {textDocument.left}{" "}
                                      </label>
                                      <label
                                        className={`btn btn-primary ${item?.stick_hand !== "left" && "active"}`}
                                        onClick={() => {
                                          updateArrayNewOrder("stick", true, item.user_code, "stick_hand", "right")

                                        }}>

                                        {textDocument.right}{" "}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="orders_golfer_club_type row"> </div>
                            </div>
                          </>}
                      </div>
                    </div>
                  ) :

                    <div className="orders_golfer_box" key={i}>
                      <div className="orders_golfer_name_box">
                        <div className="my_orders_golfer_name pull-dir">
                          {" "}
                          <span id="ufld:CUST_NAME.GOLFER1.MAALE">
                          </span>{" "}
                        </div>
                      </div>
                    </div>

                  }
                </div>
              )) : <div className="orders_golfer_box" ></div>
              }

              <div
                className={`add_golfer_box ${newOrder.length === 4 && "disabled-item"
                  }`}
              >

                <div onClick={(e) => {
                  setNewFriendDetails("")
                  handleAddPlayerClick()
                }} className="order-car-link-add-friend">
                  <div className="add-friend"></div>
                  <div
                    id="ufld:ADD_MEMBER.DUMMY.MAALE"
                    className="OrderGolf"

                  >
                    {textDocument.AddPlayer}
                  </div>
                </div>
              </div>
            </div>

            <div className="ord_approval">
              <button
                onClick={handleEndOrder}
                type="button"
                id="ufld:PB_ACCEPT.GOLFER1.MAALE"
                className="btn btn-sm btn-info btn-block btn-reg"
              >
                {textDocument.ConfirmOrder}
              </button>
            </div>
            <Footer />

          </form>
        </div >
      </div >
    </>
  );
};

export default GameOrderConfirmation;
