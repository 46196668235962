

// EntranceControl לחיצה על תמונה מבקרת כניסות למגרש
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { axiosApiGet } from "../../../network/api/RequestsApi";
import playerImg from '../../../images/pic-member-entrance.png'

const PopAddFriemds = (props) => {
    const { setSelectedFriendsCode } = props

    const [friendsList, setFriendsList] = useState();
    const [friendImg, setFriendImg] = useState();
    const [searchInput, setSearchInput] = useState('');
    const [error, setError] = useState('');

    const envDefaults = useSelector((state) => state.envDefaults);
    const textDocument = useSelector((state) => state.textDocument || {});

    useEffect(() => {
        const listFriendsDisplay = async () => {

            const res = await axiosApiGet(`/${envDefaults.customersRoute}`);
            if (res?.payload) {
                setFriendsList(res.payload);
            } else {
                console.log("error");
            }
        };
        listFriendsDisplay();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const selectFriemdImg = async (code) => {
        const res = await axiosApiGet(`/${envDefaults.customerPictureRoute}`, { customer_code: code });
        if (res?.status === "failed"){
            setError(textDocument[res?.error_message] ?? textDocument?.ServerProblem)
            setSelectedFriendsCode('')
        }

        else {
            if (res?.payload?.picture) {
                let convertUserName = res?.payload?.picture?.replace(/^data:image\/\w+;base64,/, '');
                setFriendImg(convertUserName);
            }
            else if (res?.status === "ok" || res?.status === "failed") {
                let convertUserName = playerImg?.replace(/^data:image\/\w+;base64,/, '');
                setFriendImg(convertUserName);
            }
        }



    };

    const filteredFriendsList = friendsList?.filter(friend => {
        const nameMatches = friend?.name?.toLowerCase()?.includes(searchInput?.toLowerCase());
        const latinNameMatches = friend?.latin_name?.toLowerCase()?.includes(searchInput?.toLowerCase());

        const codeMatches = friend?.code?.toLowerCase()?.includes(searchInput?.toLowerCase());
        return nameMatches || codeMatches || latinNameMatches;
    });

    return (
        <div

        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'inherit'
                }}

            >

                <div id="input_container">
                    <input
                        type="text"
                        placeholder={textDocument?.chooseFriend}
                        value={searchInput}
                        onChange={e => {

                            const selectedFriend = friendsList?.find(
                                (friend) => friend?.code === e.target?.value
                            );
                            setSearchInput(selectedFriend?.name ?? e.target.value)
                            if (selectedFriend) {
                                selectFriemdImg(e.target.value)
                                setSelectedFriendsCode(e.target.value)
                            } else {
                                setFriendImg()
                            }
                        }}
                        list="friendsList"
                    />
                    <datalist id="friendsList">
                        {filteredFriendsList?.map((c, key) => (
                            <option key={key} value={c.code}>
                                {/* {c.name} */}
                                {
                                    textDocument.language !== "Hebrew" ? c.name : c.latin_name ?? c.name

                                }
                            </option>
                        ))}
                    </datalist>

                </div >

                {friendImg && <img src={`data:image/jpeg;base64,${friendImg}`} alt={friendImg} style={{
                    width: "100px", marginTop: '1%', marginRight: ' 2%'
                }} />}
            </div>
            <div>{error}</div>

        </div >
    );
};

export default PopAddFriemds;
