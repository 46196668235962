import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ChangePassword from "./ChangePassword";
import ForgetPassword from "./ForgetPassword";
import { axiosApiPost } from "../../network/api/RequestsApi";
import { setTextPopup, setconnect } from "../../redux/login/LoginAction";
import Footer from "../Footer";
import Header from "../Header/header";
import { makeStyles } from '@mui/styles';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import engFlag from "../../images/eng-flag.png";
import HebFlag from "../../images/heb-flag.png";

import "reactjs-popup/dist/index.css";
import { GetDetail } from "../../Logic/getDetail";
import Modal from "../UI-Comps/Modal";
import '../../css/reset.css'
import { Link, Stack } from "@mui/material";
const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '0rem',

  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '50rem',

  },


}));

export default function Login() {

  const classes = useStyles();


  const textDocument = useSelector((state) => state.textDocument || {});
  const envDefaults = useSelector((state) => state.envDefaults);
  const textPopup = useSelector((state) => state.textPopup);
  const connect = useSelector((state) => state?.connect || false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [expirePassword, setExpirePassword] = useState(false);

  const [password, setPassword] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [isShowForgetPassword, setIsShowForgetPassword] = useState(false);
  const [isShowChangePassword, setIsShowChangePassword] = useState(false);
  const [loading, setLoading] = useState(false);

  let flagOpenPop = false;
  const requestApiLogin = async (e, changePassword) => {

    // If field empty - Popup and exit func
    e.preventDefault();
    if (
      password === "" ||
      username === "" || password === undefined ||
      username === undefined

    ) {

      dispatch(setTextPopup(textDocument.LoginUsernamePasswordNot));
      setShowPopup(true);
      return;
    }
    if (!connect && !changePassword) {
      dispatch(setTextPopup(textDocument.LoginClubPolicyRequired));
      setShowPopup(true);
      return;
    }
    !changePassword && setLoading(true);

    const res = await axiosApiPost(`/login`, { username, password });
    if (res?.payload) {
      localStorage.setItem("token", JSON.stringify(res.payload.token));

      if (res?.payload?.expire_password === true && !changePassword) {
        dispatch(setTextPopup(textDocument["The password has expired"]));
        setShowPopup(true);
        setExpirePassword(true)
        setLoading(false);
        return;
      }
      flagOpenPop = true;
      await GetDetail(dispatch, envDefaults);
      if (changePassword) setIsShowChangePassword(true);
      else {
        setLoading(false);
        navigate("/main");
      }
    } else {
      dispatch(setTextPopup(textDocument[res?.message] ?? textDocument?.ServerProblem));
    }
    if (flagOpenPop !== true && (connect || changePassword)) {
      setLoading(false);
      setShowPopup(true);
    }
  };


  // Go to page Registration 
  const goRegistration = () => {
    navigate("/registration");
  };
  // Go to page goRules 
  const goRules = () => {
    navigate("/rules");
  };



  function handleClick() {
    const isRTL = localStorage.language === "rtl";
    const newLanguage = isRTL ? "ltr" : "rtl";

    localStorage.setItem('language', newLanguage);
    localStorage.setItem('direction', isRTL ? "ltr" : "rtl");

    const storageEvent = new StorageEvent('storage', {
      key: 'language',
      newValue: newLanguage,
      oldValue: localStorage.language
    });

    window.dispatchEvent(storageEvent);
  }

  return (
    <>

      <Header />


      <div  className="container" >
        <div className='bg-image' >
          <div onClick={handleClick} >

            <span>
              <img
                id="ufld:FLAG.DUMMY.MAALE"
                src={textDocument?.LanguageImage === "HebFlag" ? engFlag : HebFlag}
                width="21"
                alt="English"
              />
            </span>
            <span className="select_lang" id="ufld:LANG.DUMMY.MAALE">
              &nbsp;
            </span>
            {/* </a> */}
          </div>
          <div className={classes.container} >

            <form id="login-form" className={classes.form} >
              <div >
                <div >
                  <div className="login_title">
                    <span id="ufld:HEADER.DUMMY.MAALE">
                      {textDocument.welcome}
                    </span>
                  </div>

                  <div className="user_name col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <input
                      type="userName"
                      autoComplete="userName"
                      maxLength={100}
                      className="form-control textFieldL"
                      id="ufld:USERNAME.DUMMY.MAALE"
                      placeholder={textDocument.ValidUsarname}
                      value={username}
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                    />
                  </div>

                  <div className="user_password col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <input
                      type="password"
                      // autoComplete='off'
                      maxLength={100}
                      className="form-control textFieldL"
                      id="ufld:PASWORD.DUMMY.MAALE"
                      placeholder={textDocument.EnterPassword}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <Modal
                  show={showPopup}
                  onCancel={() => {
                    setShowPopup(false);
                    if (expirePassword) {
                      setIsShowChangePassword(true);
                      setExpirePassword(false);
                    }
                  }}
                  modalButtonLeft={textDocument.modalButtonLeft}
                  header={"Tee Time"}
                >
                  <p>{textPopup}</p>

                </Modal>
                <div className="forgot_pass  col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div>
                    <span onClick={((e) => {
                      requestApiLogin(e, 1)
                    })}>
                      {textDocument.ChangePassword}
                    </span>
                  </div>
                  {isShowChangePassword && < ChangePassword
                    isShowChangePassword={isShowChangePassword}
                    setIsShowChangePassword={setIsShowChangePassword}
                  />}
                </div>
                <div className="forgot_pass  col-xs-12 col-sm-12 col-md-12 col-lg-12">


                  <ForgetPassword

                    isShowForgetPassword={isShowForgetPassword}
                    setIsShowForgetPassword={setIsShowForgetPassword}
                  />
                </div>

                <div className="site_rules_box col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="rules_checkbox pull-dir"></div>
                  <div >

                    <input
                      type="checkbox"
                      id="topping"
                      name="topping"
                      value={connect ? false : true}
                      checked={!!connect}
                      onChange={() =>
                        dispatch(setconnect(connect ? false : true))
                      }
                    />
                    &nbsp;
                    {textDocument.IReadThe}
                    &nbsp;
                    <span
                      className="fake-link"
                      // href=""
                      onClick={goRules}
                      id="ufld:HDR_TAKANON.DUMMY.MAALE"
                    >
                      {textDocument.Rules}
                    </span>

                  </div>

                </div>
                {/* <Stack>
                <Link href="https://forms.iforms.co.il/Show_form/i34gmh90rlqnsr" underline="none"target="_blank">
                מעבר לחשבונית דיגיטלית
                </Link>
                <Link href="https://forms.iforms.co.il/Show_form/cwz09x67" underline="none"target="_blank">
                Moving to a digital invoice
                </Link>
                <Link href="https://forms.iforms.co.il/Show_form/1lyaot28" underline="none"target="_blank">
                Passage à la facture électronique
                </Link></Stack>
                <br/> */}
                <div className="row NoMargin">
                  <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                    {" "}
                  </div>
                  <div
                    // className="parent-container"
                    className="form-group enterBtn col-xs-6 col-sm-6 col-md-6 col-lg-6"
                  >

                    <button
                      className="btn btn-sm btn-info btn-block btn-enter right-align"
                      id="ufld:ENTER.DUMMY.MAALE"
                      onClick={requestApiLogin}
                    >
                      {textDocument.Enter}

                    </button>
                    {loading && <Box sx={{
                      marginTop: '-20%',
                      marginRight: '45%',
                      marginLeft: '38%',
                      '& .MuiCircularProgress-root': {
                        marginBottom: '0',
                        marginRight: '0'
                      }
                    }}>
                      <CircularProgress />
                    </Box>}

                  </div>

                  <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                    {" "}
                  </div>
                </div>
                <div className="row newcustomer_box">
                  <div className="newcustomer_lbl col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <span id="ufld:HDR_NEWCUSTOMER.DUMMY.MAALE">
                      {textDocument.NewCostumer}

                    </span>
                  </div>
                  <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                    {" "}
                  </div>
                  <div className="form-group enterBtn col-xs-6 col-sm-6 col-md-6 col-lg-6 ">
                    <button
                      className="btn btn-sm btn-info btn-block btn-reg centered"
                      id="ufld:NEWCUSTOMER.DUMMY.MAALE"
                      onClick={goRegistration}
                    >
                      {textDocument.SignUp}
                    </button>
                  </div>


                </div>
              </div>
            </form>
          </div>
        </div >
        <Footer />
      </div >

    </>

  );
}

