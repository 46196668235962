//import '../css/reset.css'
import '../../css/golf-rtl.css'
import '../../css/golfstyle.css'
import { Link } from 'react-router-dom';
import React from 'react';
import Footer from "../Footer";
import Header from "../Header/header";
import { useSelector, useDispatch } from "react-redux";
import { setconnect } from "../../redux/login/LoginAction";
import { Box, Typography, Button } from '@mui/material';


export default function Rules() {


    const textDocument = useSelector((state) => state.textDocument || {});

    const dispatch = useDispatch();

    const handleOnChange = () => {

        dispatch(setconnect(true))
    };
    return (
        <>
            <Header />
            <div className="container">

                <Box>

                    <Box sx={{ mx: "auto", maxWidth: 1200, px: 2 }}>
                        <Typography variant="h4" align="center" sx={{ my: 4 }}>
                            {textDocument.RulesClubPolicyHader}
                        </Typography>

                        <Box sx={{ my: 4 }}>
                            {textDocument?.rules?.map((line, index) => (
                                <div key={index} style={{ display: "flex" }}>
                                    <Typography variant="h6" sx={{
                                        fontWeight: "bold", marginRight: "5rem", marginTop: '-0.5%'
                                    }}>
                                        {index + 1}. &nbsp;
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontFamily: "system-ui", flexGrow: 1 }}>
                                        {line}
                                    </Typography>
                                </div>
                            ))}


                            {textDocument?.rulesSection?.map((line, index) => (
                                <Typography key={index} variant="body1" sx={{ fontFamily: "system-ui", marginRight: '9%', flexGrow: 1, marginLeft: '9%' }}>
                                    {line}
                                </Typography>
                            ))}
                        </Box>

                        <Box sx={{ my: 4 }}>
                            {textDocument?.rulesEnd?.map((line, index) => (
                                <Typography key={index} variant="body1" sx={{
                                    fontFamily: "system-ui", fontWeight: "bold",
                                    marginRight: "5rem", marginLeft: "5rem"
                                }}>
                                    {line}
                                </Typography>
                            ))}
                        </Box>

                        <Box >

                            <Box sx={{ my: 4 }} display="-webkit-flex" justifyContent="space-around" >
                                <Link to="/Login" >
                                    <Button
                                        variant="contained"
                                        size="large"
                                        sx={{
                                            backgroundColor: "#003B36",
                                            color: "white",
                                            "&:hover": {
                                                backgroundColor: "#228c3d",
                                            }
                                        }}
                                    >
                                        {textDocument.RulesBack}
                                    </Button>
                                </Link>

                                <Link to="/Login" >
                                    <Button
                                        variant="contained"
                                        size="large"
                                        onClick={handleOnChange}
                                        sx={{
                                            backgroundColor: "#003B36",
                                            color: "white",
                                            "&:hover": {
                                                backgroundColor: "#228c3d",
                                            }
                                        }}
                                    >
                                        {textDocument.modalButtonLeft}
                                    </Button>
                                </Link>

                            </Box>


                        </Box>


                    </Box>

                </Box>
                <Footer />
            </div>
        </>
    )
}
