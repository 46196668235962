import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '20%',
                    marginBottom: '25vh',
                    marginRight: '50%',
                    marginLeft: '50%'
                },
                colorPrimary: {
                    color: '#228c3d',
                },
            },
        },

    },
});

export default theme;
