import React from 'react';

import './Backdrop.css';

// המסך השחור שמאחורי הפופאפ
const Backdrop = props => {
  return (
    <div className="backdrop" onClick={props.onClick}></div>

  )
  // ReactDOM.createPortal(
  // document.getElementById('backdrop-hook')
  // );
};

export default Backdrop;
